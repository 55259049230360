import moment from 'moment-timezone'
import {formatShockData, svgCircle, getDateFormat, round} from './helpers.js'

/**
 * Builds a single object of options for a line chart
 *  - Sets good default values to be overridden as required
 * @param {Object} chartConfig - Chart Configurations
 * @param {string} title - Chart title
 * @param {Array} series - Array of data series points of chart data
 * @param {Array} options - Object containing optional formatting parameters
 * @param {Object} options.xAxis - Object defining x-axis {data: [###]}.  If no
 * value is provided a default of {type: 'time', boundaryGap: false} will be used
 * @param {Array} options.yAxis - Array or object of y-axis defintions<br>
 * - If array is provided, process it.  Should be of this format: {name: ###, ymax: ###, ymin: ###}
 * - If simple object is provided use it directly.
 * - if alternate value for formatter provided, update the format function
 * @param {Array} options.vMapPieces - Array of names of visual maps (color shading) for chart
 * @param {string} options.subtitle - Subtitle for chart
 * @param {string} options.tooltip - Tootlip name to override default.  Accepted values are "door" and "datetime"
 * @param {string} options.colors - Array of color values to overwrite default color pallette
 * @returns {Object} Object definition for line chart
 */
// skipcq JS-0044 : Avoid Cyclomatic complexity more than allowed
 export const chartOptionFactory = (chartConfig, title, series, options = {}, colors = {}, tmz = 'Etc/GMT') => {
  // Y Axis Format
  let yAxisSet = []
  if (!options.yAxis) {
    yAxisSet = [{type: 'value'}]
  } else if (Array.isArray(options.yAxis)) {
    for (const axis of options.yAxis) {
      const yAxisVal = {
        type: 'value',
        name: axis.name,
        scale: true,
        max: typeof axis.ymax === 'number' ? axis.ymax : null,
        min: typeof axis.ymin === 'number' ? axis.ymin : null,
        axisLabel: {formatter: '{value}'},
        axisPointer: {snap: true},
        yAxisIndex: typeof axis.index === 'number' ? axis.index : 0
      }
      // If the yAxis item have splitline value, then apply it to the axis
      // if (axis.hasOwnProperty('splitLine')) {
      if (Object.prototype.hasOwnProperty.call(axis, 'splitLine')) {
        yAxisVal.splitLine = axis.splitLine
      }
      if (axis.formatter === 'percent') {
        yAxisVal.axisLabel.formatter = function (value) {
          const pct = value * 100
          return `${pct} %`
        }
      }
      yAxisSet.push(yAxisVal)
    }
  } else {
    yAxisSet = options.yAxis
  }

  // Set X Axis if provided in options
  const xAxisSet = options.xAxis || [{type: 'time', boundaryGap: false}]

  // If XAxisSet is an array and it's type is time, then format the xaxis label
  if (xAxisSet[0] && xAxisSet[0].type === 'time') {
    // XAxis label object
    const axisLabel = {
      axisLabel: {
        interval: 1,
        overflow: 'break',
        width: 60,
        formatter (value) { // XAxis label formatter
          return moment(value).tz(tmz).format(`${getDateFormat('short')} HH:MM`)
        }
      }
    }
    xAxisSet[0] = Object.assign(xAxisSet[0], axisLabel)
  }

  // Define all visual maps series and build visual map for object
  const vMapPieces = chartConfig.vMapPieces
  let visualMap = []
  if (options.vMapPieces) {
    options.vMapPieces.forEach((vmapPiece, indx) => {
      if (vmapPiece && vMapPieces[vmapPiece]) {
        visualMap.push({show: false, seriesIndex: indx, pieces: vMapPieces[vmapPiece]})
      }
    })
  }

  if (options.visualMap) {
    visualMap = options.visualMap
  }

  // Default color array definition
  const defaultColors = (typeof colors.length === 'undefined' || colors.length === 0) ? chartConfig.colors.default : colors
  // color: ['#C83232', '#183663', '#197a08', '#f49242', '#702f57', '#f4c542', '#91c7ae', '#749f83', '#ca8622', '#bda29a'],

  // Build series object from series
  const dataSeries = series.map(itm => {
    const seriesItem = {
      name: itm.name,
      showSymbol: false,
      yAxisIndex: itm.yAxisIndex || 0,
      type: itm.type || 'line',
      smooth: true,
      data: itm.data
    }
    if (itm.markLine) seriesItem.markLine = itm.markLine
    if (itm.type) {
      if (itm.type === 'custom') { seriesItem.renderItem = itm.renderItem }
      if (itm.type === 'pie') { seriesItem.radius = itm.radius }
      if (itm.type === 'bar') { seriesItem.stack = itm.stack }
    }
    if (itm.trendline) {
      seriesItem.markLine = {
        symbol: 'circle',
        label: {show: true, formatter: '{b}: {c}'},
        data: itm.trendline
      }
    }
    if (itm.itemStyle) {
      seriesItem.itemStyle = itm.itemStyle
    }
    if (itm.lineStyle) {
      seriesItem.lineStyle = itm.lineStyle
    }
    if (itm.zlevel) {
      seriesItem.zlevel = itm.zlevel
    }
    return seriesItem
  })

  let tooltipDef = {}
  switch (options.tooltip) {
    case 'datetime':
      tooltipDef = {
        trigger: 'axis',
        axisPointer: {
          label: {show: false},
          type: 'cross'
        },
        formatter (params) {
          let output = moment(params[0].value[0], 'x').tz(tmz).format(`${getDateFormat('short')} HH:mm (z)`)
          for (const param of params) {
            const val = param.seriesName === 'Charge' && param.value[1] <= 1 ? `${round(param.value[1] * 100, 0)}%` : param.value[1]
            output += `<br>${svgCircle(param.color)} ${param.seriesName}: ${val}`
          }
          return output
        }
      }
      break
    case 'percent':
      tooltipDef = {formatter: 'Type: {b0}<br />Count: {c0} [{d0}%]'}
      break
    case 'door':
      tooltipDef = {
        trigger: 'item',
        axisPointer: {
          type: 'cross'
        },
        formatter (params) {
          const doorNum = params.data[0] + 1
          const dateTime = moment(params.data[1], 'x').tz(tmz).format(`${getDateFormat('short')} H:00`)
          return `Door ${doorNum} <br />${dateTime}`
        }
      }
      break
    case 'none':
    default:
      tooltipDef = {show: false}
      break
  }

  // Generate output
  const output = {
    title: {
      text: title,
      subtext: options.subtitle || ''
    },
    animation: false,
    color: options.colors || defaultColors,
    tooltip: tooltipDef,
    toolbox: {},
    // toolbox: {
    //   // left: 'right',
    //   right: '5%',
    //   feature: {
    //     dataView: {
    //       title: 'Data View',
    //       lang: ['Data View', 'Close', 'Save'],
    //       optionToContent: function (opt) {
    //         // get column headings from the options
    //         let headings = opt.series.map(x => x.name).join('</td><td>')
    //         var table = '<table style="width:100%;text-align:center"><tbody><tr>' +
    //               '<td>Time:</td><td>' + headings + '</td></tr>'
    //         // Add in the rwos
    //         for (var i = 0, l = opt.series[0].data.length; i < l; i++) {
    //           let rowData = opt.series.map(x => x.data[i][1]).join('</td><td>')
    //           table += '<tr>' +
    //               '<td>' + moment(opt.series[0].data[i][0]).format('MM-DD-YYYY HH:mm:ss') + '</td>' +
    //               '<td>' + rowData + '</td>' +
    //               '</tr>'
    //         }
    //         table += '</tbody></table>'
    //         return table
    //       }
    //     },
    //     saveAsImage: {title: 'Download Image'},
    //     restore: {title: 'Refresh'}
    //   }
    // },

    legend: options.legend || {},
    xAxis: xAxisSet,
    yAxis: yAxisSet,
    visualMap,
    series: dataSeries
  }
  return output
}

/**
 * Builds a single object of filters for a table
 *  - Sets good default values to be overridden as required
 * @param {Object} filters - list of filters with name, column and
 * value
 * @param {string} filters.name - Name of column itable
 * @returns {Object} Object definition for line chart
 */
export const filterFactory = (fltrDef, preFilter = false) => {
  // Function to allow one (or more?) preset filters to set
  // value and active parameters
  const preFilterVal = (preFilter !== false) ? preFilter : []
  /**
   *
   * @param {Array} flt - Filter item
   * @returns {Array} - List of object with filter values
   */
  const mapFn = flt => {
    const getstore = preFilterVal.filter((x) => x.col === flt.col)[0]
    // console.log('map filter', getstore)
    if (typeof getstore !== 'undefined' && getstore.col === flt.col) {
      let value = getstore.val
      // If the name is not found in the alert definition, then use it from
      // the state configuration
      const name = flt.name ? flt.name : getstore.name
      // If it is a multi value filter, If the input value is an array assign as it is. Else assign [] as the default value
      if (flt.type === 'checkbox' || flt.type === 'multiSelect') {
        value = Array.isArray(value) ? value : []
      }
      return {...flt, active: true, val: value, name}
    }
    // If it is a multi value filter, assign [] as the default value
    const value = (flt.type === 'checkbox' || flt.type === 'multiSelect') ? [] : ''
    return {...flt, active: false, val: value}
  }
  const filters = fltrDef.map(mapFn)

  return {
    clear: (fltr) => {
      // Clear filter used to remove a single in-use filter from
      // the list of active filters - to do so, set active = false
      // and clear val on table filters...
      //
      filters.forEach((ele) => {
        if (fltr.col === ele.col) {
          ele.active = false
          ele.val = ''
        }
      })
    },
    getAll: () => { return filters },
    getActive: () => { return filters.filter(flt => flt.active) },
    reset: () => {
      // Called by filter modal to reset values in activeFilters
      // list when modal has update button triggered.
      filters.forEach((ele) => {
        ele.active = ele.val !== ''
      })
    }
  }
}

/**
 * Builds a single object of options for a scatter chart
 *  - Sets good default values to be overridden as required
 * @param {string} title - Chart title
 * @param {Array} series - Array of data series points of chart data
 * @param {Array} options - Object containing optional formatting parameters
 * @param {Object} options.xAxis - Object defining x-axis {data: [###]}.  If no
 * value is provided a default of {type: 'time', boundaryGap: false} will be used
 * @param {Array} options.yAxis - Array or object of y-axis defintions<br>
 * - If array is provided, process it.  Should be of this format: {name: ###, ymax: ###, ymin: ###}
 * - If simple object is provided use it directly.
 * - if alternate value for formatter provided, update the format function
 * @param {Array} options.vMapPieces - Array of names of visual maps (color shading) for chart
 * @param {string} options.subtitle - Subtitle for chart
 * @param {string} options.tooltip - Tootlip name to override default.  Accepted values are "door" and "datetime"
 * @param {string} options.colors - Array of color values to overwrite default color pallette
 * @returns {Object} Object definition for line chart
 */
export const scatterChartOptionFactory = (title, data, minTime, maxTime, chartOptions = {}, tmz = 'Etc/GMT') => {
  let symbol = 'circle'
  if (chartOptions.symbol) symbol = chartOptions.symbol
  const output = {
    tooltip: {
      position: 'top',
      formatter (params) {
        // Format tooltip label using the timezone
        const displayTime = moment(params.data[0]).tz(tmz).format(`${getDateFormat()} HH:mm:ss`)
        // console.log('displayTime', params)
        return `${displayTime}, ${params.data[1]}`
      }
    },
    title: [{
      textBaseline: 'middle',
      top: '10%',
      text: title
    }],
    singleAxis: [{
      left: 50,
      right: 50,
      min: minTime,
      max: maxTime,
      type: 'time',
      boundaryGap: false,
      top: '40%',
      height: `${(100 / 7 - 10)} %`,
      axisLabel: {
        interval: 1,
        overflow: 'break',
        width: 60,
        formatter (value) {
          return moment(value).tz(tmz).format(`${getDateFormat('short')} HH:mm`)
        }
      }
    }],
    series: [{
      singleAxisIndex: 0,
      coordinateSystem: 'singleAxis',
      type: 'scatter',
      data,
      symbol,
      // symbolOffset: size ? [0, '20%'] : [0, 0],
      symbolSize (dataItem) {
        return chartOptions.size && chartOptions.size.length > 0 ? chartOptions.size : (dataItem[1] / 5) + 10
      }
    }]
  }
  if (chartOptions.colors && chartOptions.colors.length > 0) {
    output.color = chartOptions.colors
    output.series[0].itemStyle = {
      color (dataItem) {
        // console.log('dataItem', dataItem.value)
        return chartOptions.colorsCallback(dataItem.data)
      }
    }
  }
  if (chartOptions.tooltipFormatter) {
    output.tooltip.formatter = function (params) {
      return chartOptions.tooltipFormatter(params.data)
    }
  }
  return output
}

/**
 *
 * @param {Object} shockData - Given shock data
 * @param {String} macId - Given Mac ID
 * @param {Boolean} showTitle - Control to show the title or not
 * @param {Object} chartConfig - Input chart options
 * @returns {Object} - Returns the chart options
 */
export const shockChartOptionFactory = (shockData = {}, macId = '', showTitle = true, chartConfig = {}, preformattted = false) => {
  if (Object.keys(shockData).length === 0) {
    return {}
  }
  // If the shock data is already formatted, then use it. Else format the data
  const formattedShockData = preformattted ? shockData : formatShockData(shockData)
  const visibleSeries = [
    {
      name: 'X Axis',
      showSymbol: false,
      type: 'line',
      data: formattedShockData.X
    },
    {
      name: 'Y Axis',
      showSymbol: false,
      type: 'line',
      data: formattedShockData.Y
    },
    {
      name: 'Z Axis',
      showSymbol: false,
      type: 'line',
      data: formattedShockData.Z
    },
    {
      name: 'Aggregate',
      showSymbol: false,
      type: 'line',
      data: formattedShockData.RMS
    }
  ]
  const title = showTitle ? `Shock Event [${macId}]` : ''
  const xAxis = {
    type: 'value',
    name: 'Time (ms)',
    boundaryGap: false,
    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  }
  const yAxis = [
    {name: 'Shock (g)'}
  ]
  const legend = {bottom: 10}
  const series = visibleSeries
  // const options = {xAxis}
  const options = {xAxis, yAxis, legend}
  // let opts = chartOptionFactory(title, series, options)
  // console.log('OPTS: ', opts)
  return chartOptionFactory(chartConfig, title, series, options)
}

/**
 * Builds a single object of options for a heat map chart
 *  - Sets good default values to be overridden as required
 * @param {Object} chartConfig - Chart Configurations
 * @param {Array} seriesData - Array of data series points of chart data
 * @param {Array} timestamps - Array of time series data
 * @param {Array} chimes - Array of chime list
 * @param {Array} chimeMarkLines - Array of chime mark line dates like in country date
 * @param {Boolean} options.showLabel - Defines to show the label value on the chart or not
 * @param {string} chartConfig.tooltipLabel - Tooltip label value to show in the chart
 * @param {string} options.gridHeight - GridHeight value for the chart
 * @param {string} options.labelUnit - To show the unit of the value like %, deg...
 * @param {Object} options.vMap - VMap config for the chart
 * @returns {Object} Object definition for line chart
 */
export const chimeNetworkChartFactory = (chimes, timestamps, seriesData, chimeMarkLines, chartConfig = {}) => {
  const tooltipLabel = chartConfig.tooltipLabel || 'Battery'
  const labelUnit = chartConfig.labelUnit || ''
  const gridHeight = chartConfig.gridHeight || '80%'
  seriesData = seriesData.map((item) => {
    return [item[1], item[0], item[2] || '-'] // re ordering the series data
  })
  const vMapDef = {
    min: 0,
    max: 100,
    calculable: true,
    inverse: false,
    orient: 'horizontal',
    left: 'center',
    bottom: '0%',
    inRange: {
      color: ['#1340C9', '#006600', '#DAA520', '#F7741D', '#B90909']
    }
  }
  const vmapConfig = chartConfig.vMap
  const visualMap = Object.assign(vMapDef, vmapConfig)
  const opts = {
    tooltip: {
      position: 'top',
      // formatter: 'Time: {b0}<br />Battery Level: {c1}<br />{a}-{b}-{c}-{d}'
      formatter (params) {
        if (params.componentType === 'markLine') {
          return `Type: ${params.data.label.formatter}<br />Date: ${params.value}`
        }
        const value = params.data[2] + labelUnit
        const tmstmp = params.name
        const row = chimes[params.data[1]]
        return `Chime: ${row}<br />${tooltipLabel}: ${value} <br />Time: ${tmstmp}`
      }
    },
    animation: false,
    grid: {
      height: gridHeight,
      y: '17%',
      top: '50'
    },
    xAxis: {
      type: 'category',
      data: timestamps,
      splitArea: {
        show: false
      }
    },
    // xAxis: { type: 'time', boundaryGap: false },
    yAxis: {
      type: 'category',
      data: chimes,
      splitArea: {
        show: false
      }
    },
    visualMap,
    series: [{
      name: 'Battery Percent',
      type: 'heatmap',
      data: seriesData,
      label: {
        normal: {
          show: false
        }
      },
      itemStyle: {
        emphasis: {
          shadowBlur: 100,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      markLine: {
        symbolSize: 0,
        lineStyle: {
          normal: {
            width: 5,
            type: 'solid'
          }
        },
        data: chimeMarkLines
      }
    }]
  }
  return opts
}
