<template>
  <div class="field">
    <label class="label">{{label}}</label>
    <ValidationProvider :name="label" :rules="{required: true}"  v-slot="{ errors }">
      <div class="control">
        <span class="select">
          <select v-model="locId" :name="label">
            <option value="">-- Select {{label}} --</option>
            <template v-for="loc in locations">
              <option :key="loc.value" :value="loc.value" v-if="checkLocationOption(loc.value)">{{loc.name}}</option>
            </template>
          </select>
        </span>
      </div>
      <span class="help is-danger">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  name: 'LocationSelect',
  computed: {
    locations () {
      let locs = Object.values(this.$store.state.locations.all)
      // const vm = this
      const filter = this.filter
      if (filter.length > 0) locs = locs.filter(loc => loc.locType.toLowerCase().includes(filter.toLowerCase()))
      locs.sort((a, b) => (a.name > b.name) ? 1 : -1)
      return locs.map(loc => { return {name: loc.name, value: loc.shippingLocId} }).sort()
    }
  },
  data () {
    return {
      locId: ''
    }
  },
  mounted () {
    if (this.default) this.locId = this.default
  },
  methods: {
    checkLocationOption (locId) {
      // If the field parameter is an array, then do not filter options
      if (this.field.length > 1) {
        return true
      }
      const validateField = this.field[0] === 'origin' ? 'destination' : 'origin'
      // If the location field is origin, then check the destination value in state. If the value is found in destination, then filter the option in list
      // same will be validated for the destination field
      // Shipments form now changed to global form object
      // skipcq: JS-W1044.  Allow Logical operator can be refactored to optional chain
      const valid = (this.$store.state.form.new && this.$store.state.form.new[validateField] && this.$store.state.form.new[validateField].shippingLocId && this.$store.state.form.new[validateField].shippingLocId === locId)
      return !valid
    }
  },
  props: {
    default: String,
    mode: String,
    label: String,
    field: Array,
    reset: Boolean,
    filter: {
      type: String,
      default: ''
    }
  },
  watch: {
    reset (val) {
      if (val) {
        // If reset is true, then set the value as blank
        // If the default value is passed, then set the value
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        this.locId = this.default || ''
      }
    },
    locId () {
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      const location = this.$store.state.locations.all[this.locId] || false
      for (const locField of this.field) {
        // If the value is selected then set the field value in state. Else set empty value for the field in state
        if (location) {
          const locationObj = {
            name: location.name,
            latitude: location.latitude,
            longitude: location.longitude,
            shippingLocId: location.shippingLocId
          }
          this.$store.commit('SET_FORM_FIELD', {key: locField, value: locationObj, mode: this.mode})
        } else {
          this.$store.commit('SET_FORM_FIELD', {key: locField, value: '', mode: this.mode})
        }
      }
    }
  }
}
</script>
