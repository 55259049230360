<template>
  <div>
    <level-bar>
      {{pagename}} - {{warehouseName}}
    </level-bar>

    <div class="columns">
      <div class="column">
          <!-- left column -->
        <div>
          <intelyt-card>
            <template slot="header">DH Units</template>
            <div>
              <base-table
                :data="DHUnitData"
                :columns="DHUnitTableCols"
                :options="DHUnitTableOptions"
                :isNarrow="true"
              >
              </base-table>
            </div>
          </intelyt-card>
          <intelyt-card>
            <template slot="header">Indoor Conditions</template>
            <div>
              <nav class="level">
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">Temperature</p>
                    <intelyt-gauge
                      configuration="temperature"
                      :value="temperature"
                    >
                    </intelyt-gauge>
                    <p>{{temperature}} °F</p>
                  </div>
                </div>
                <div class="level-item has-text-centered">
                  <div>
                    <p class="heading">Humidity</p>
                    <intelyt-gauge
                       configuration="humidity"
                       :value="humidity"
                    >
                    </intelyt-gauge>
                    <p>{{humidity}} %</p>
                  </div>
                </div>
              </nav>
            </div>
          </intelyt-card>
          <intelyt-card>
            <template slot="header">Ambient Conditions</template>
            <nav class="level">
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Temperature </p>
                  <p class="title" :style="noBottomMargin">{{ambientConditions.temperature}} &deg;F</p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Humidity</p>
                  <p class="title" :style="noBottomMargin">{{ambientConditions.humidity}}%</p>
                </div>
              </div>
            </nav>
          </intelyt-card>
          <intelyt-card v-if="this.hasDoors">
            <template slot="header">Door Status</template>
            <nav class="level">
              <div class="level-item has-text-centered"  v-for="(door, indx) in doorStatus" :key="indx">
                <div>
                  <p class="heading" :style="noBottomMargin">{{door.name}}</p>
                    <span class="icon is-large icon-red" v-if="door.status === 'Open'">
                      <base-icon name="enter" />
                    </span>
                    <span class="icon is-large icon-green" v-if="door.status === 'Closed'">
                      <base-icon name="application" />
                    </span>
                  <p class="heading" :style="noBottomMargin">{{door.status}}</p>
                </div>
              </div>
            </nav>
          </intelyt-card>
          <intelyt-card v-if="runtimes && runtimes.length > 0">
            <template slot="header">Runtimes</template>
            <nav class="level">
              <div class="level-item has-text-centered" v-for="(entry, indx) in runtimes" :key="indx">
                <div>
                  <p class="heading" :style="noBottomMargin">{{Object.keys(entry)[0]}}</p>
                  <p class="title" :style="noBottomMargin">{{Object.values(entry)[0]}}</p>
                  <p class="heading" :style="noBottomMargin">Hours</p>
                </div>
              </div>
            </nav>
          </intelyt-card>
        </div>
      </div>
        <!-- middle column -->
      <div class="column">
        <div>
          <intelyt-card :reportOptions="reportOptions">
            <template slot="header">Indoor Summary</template>
            <nav class="level">
              <template v-for="(data, i) in indoorSummary()">
                <div class="level-item has-text-centered" :key="i">
                  <div>
                    <p class="heading" v-html="data.name"></p>
                    <p class="title" :style="noBottomMargin" v-html="data.value"></p>
                  </div>
                </div>
              </template>
            </nav>
          </intelyt-card>
          <intelyt-card :style="chartWidthCalc">
            <template slot="header">Historical Conditions {{historicalChartTitleText}}</template>
            <base-chart classname='chart-style'
                      :showLoadingIcon='showLoading'
                      :options='chartOptions'>
            </base-chart>
            <base-chart classname='chart-style'
                      :showLoadingIcon='showLoading'
                      :options='ambientChartOptions'>
            </base-chart>
            <base-chart v-if="this.hasDoors"
                      :style="doorChartHeightCalc"
                      classname='chart-style'
                      :showLoadingIcon='showLoading'
                      :options='doorChartOptions'>
            </base-chart>
            <base-chart v-if="this.hasPower"
                      classname='chart-style'
                      :showLoadingIcon='showLoading'
                      :options='powerChartOptions'>
            </base-chart>
          </intelyt-card>
        </div>
      </div>
      <!-- right column -->
      <div class="column">
        <div>
          <base-slider v-on:sliderChange="updateRange" :intervals="sliderInterval" :units="sliderUnit"></base-slider>
          <intelyt-card maxHeight="250px" :scrolling="true">
            <template slot="header">Notifications</template>
            <template slot="controls">
              <!-- <base-icon name="message_add" @click="openModal('createAlert')" v-tooltip="'Create Message'" v-if="showAlertMessage" /> -->
              <!-- <base-icon name="message_add" v-tooltip="'Create Message'" v-if="showAlertMessage" /> -->
            </template>
            <div class="tabs is-boxed">
              <ul class="noMarginleft">
                <li v-for="(tab, idx) in getNotificationTabs" :key="idx" :class="{'is-active': (activeTab === idx)}"><a @click="activateTab(idx)">{{tab.name}} <span :class="{ 'text-danger': tab.unAcknowledged }">({{tab.value.length}})</span> </a></li>
              </ul>
            </div>
            <div class="content">
              <div v-for="(tab, idx) in getNotificationTabs" :key="idx" :id="idx" class="content-tab" v-show="activeTab === idx">
                <div class="field-group">
                  <div class="field">
                    <template v-for="msg in tab.value">
                      <intelyt-alert :alert=msg :hideId="true" :key="msg.entryId"></intelyt-alert>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </intelyt-card>
          <intelyt-card>
            <template slot="header">Warehouse Location</template>
            <template>
            <intelyt-map
               :markers="activeMarkers.warehouse && activeMarkers.warehouse.active ? markers : []"
               :mapWidth="mapWidthCalc">
            </intelyt-map>
            <intelyt-map-legend
              v-if="legendMarkers.length > 0"
              :markers="legendMarkers"
              :activeMarkers="activeMarkers"
            ></intelyt-map-legend>
            </template>
          </intelyt-card>
        </div>
      </div>
    </div>
    <report-config-modal
      :isActive="modalIsActive.reportConfig"
      :selectedReport="selectedReport"
      :externalControls="externalControls"
      v-on:generate="previewReport"
      v-on:close="closeModal('reportConfig')"
    >
    </report-config-modal>
    <report-results-modal
      :reportData="reportData"
      :reportSearch="false"
      :fileName="reportFileName"
      :isActive="modalIsActive.reportResults"
      v-on:close="closeModal('reportResults')"
    >
    </report-results-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import IntelytGauge from '@/components/IntelytGauge'
import IntelytMapLegend from '@/components/IntelytMapLegend'
import { generateIconSVG, getMapWidth, renderRectangle, getRoundedAverage, buildTempAndHumidChartOptions, getDateFormat } from '@/store/helpers'
import {chartOptionFactory} from '@/store/factories'
import moment from 'moment-timezone'
import BaseSlider from '@/components/BaseSlider'

import {modalWindowManager} from '@/store/mixins'

export default {
  components: {
    BaseSlider,
    IntelytGauge,
    IntelytMapLegend
  },
  computed: {
    DHUnitData: function () {
      return [...this.$store.state.warehouses.all[this.$route.query.id].dhUnits]
    },
    getAlerts: function () {
      const alerts = this.$store.getters['alerts/getAlertsByDate'](this.range, this.macId).filter(alert => alert.level === '2')
      const haveUnAcknowledged = alerts.filter(alert => alert.ackBy.length === 0).length > 0
      // console.log('alerts', alerts, haveUnAcknowledged)
      return {data: alerts, unAcknowledged: haveUnAcknowledged}
    },
    alertList: function () {
      return this.$store.getters['alerts/getAlertsByDate'](this.range, this.macId)
    },
    ambientChartOptions: function () {
      const station = this.$store.state.warehouses.all[this.$route.query.id].weatherStationId || ''
      let title = `Ambient Temperature & Humidity`
      const isC = this.$store.state.user.temperatureUnit === 'Celsius'
      const chartConfig = this.$store.state.configuration.charts
      // const humidityExists = station && this.$store.state.weather.all[station] && this.$store.state.weather.all[station][0] && this.$store.state.weather.all[station][0].hasOwnProperty('humidity')
      const humidityExists = station && this.$store.state.weather.all[station] && this.$store.state.weather.all[station][0] && Object.prototype.hasOwnProperty.call(this.$store.state.weather.all[station][0], 'humidity')
      let humidtyData = []
      if (humidityExists) humidtyData = this['weather/ambientReadings'](station, 'humidity', this.range)
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {
        temp: this['weather/ambientReadings'](station, 'temperature', this.range),
        humid: humidtyData,
        isC,
        chartConfig,
        title,
        tmz
      }
      return buildTempAndHumidChartOptions(options)
    },
    ambientConditions: function () {
      const station = this.$store.state.warehouses.all[this.$route.query.id].weatherStationId
      if (station && this.$store.state.weather.all[station]) {
        const weather = this.$store.state.weather.all[station]
        return weather[weather.length - 1]
      } else {
        return {temperature: 1, humidity: 0}
      }
    },
    chartOptions: function () {
      let title = 'Indoor Temperature & Humidity'
      // const humidityExists = this.$store.state.warehouses.all[this.$route.query.id].tsData[0] && this.$store.state.warehouses.all[this.$route.query.id].tsData[0].hasOwnProperty('humidity')
      const humidityExists = this.$store.state.warehouses.all[this.$route.query.id].tsData[0] && Object.prototype.hasOwnProperty.call(this.$store.state.warehouses.all[this.$route.query.id].tsData[0], 'humidity')
      let humidtyData = []
      if (humidityExists) humidtyData = this['warehouses/timeSeries'](this.$route.query.id, 'humidity', this.range)
      const isC = this.$store.state.user.temperatureUnit === 'Celsius'
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {
        temp: this['warehouses/timeSeries'](this.$route.query.id, 'temperature', this.range),
        humid: humidtyData,
        isC,
        chartConfig,
        title,
        tmz
      }
      return buildTempAndHumidChartOptions(options)
    },
    chartWidthCalc: function () {
      // return round(document.documentElement.clientWidth * 0.7, 0)
      let width = this.$parent.windowWidth * 0.95
      if (this.$parent.windowWidth > 981) {
        width = this.$parent.windowWidth * 0.48
      }
      return 'width: ' + width + 'px;'
    },
    doorChartHeightCalc: function () {
      // return round(document.documentElement.clientWidth * 0.7, 0)
      const numOfDoors = this.$store.state.warehouses.all[this.$route.query.id].doors
      let height = 120 + numOfDoors * 30
      return `height: ${height}px;`
    },
    doorChartOptions: function () {
      /* let minTime = Number(moment(this.slider.value[0], 'M-DD-YY').format('x'))
      let maxTime = Number(moment(this.slider.value[1], 'M-DD-YY').endOf('day').format('x')) */
      // Array like ['Door 1', 'Door 2', 'Door 3']
      const numOfDoors = this.$store.state.warehouses.all[this.$route.query.id].doors
      // If door names found in the device, then use it else loop the number of devices and
      // Add count to it like Door 1, Door 2...
      const doorLabels = this.$store.state.warehouses.all[this.$route.query.id].doorNames || Array.from(new Array(numOfDoors), (val, index) => `Door ${index + 1}`)
      const title = `Door Position`
      // const color: ['green', '#1e207a'],
      const xAxis = [{
        type: 'time',
        min: this.range[0],
        max: this.range[1],
        boundaryGap: false
      }]
      const yAxis = {data: doorLabels, nameTextStyle: {width: 25, overflow: 'breakAll'}}
      const series = [{
        type: 'custom',
        renderItem: renderRectangle,
        data: this['warehouses/doorData'](this.$route.query.id)
      }]
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const options = {xAxis: xAxis, yAxis: yAxis, tooltip: 'door', colors: chartConfig.colors.lineChart, legend: {bottom: 5}}
      return chartOptionFactory(chartConfig, title, series, options, {}, tmz)
    },
    doorStatus () {
      return this.$store.state.warehouses.all[this.$route.query.id].doorStatus
    },
    getNotificationTabs () {
      return {alerts: {name: `Alerts `, value: this.getAlerts.data, unAcknowledged: this.getAlerts.unAcknowledged}}
    },
    hasDoors: function () {
      const wh = this.$store.state.warehouses.all[this.$route.query.id]
      return wh.doors && wh.doors > 0
    },
    hasPower: function () {
      return this.$store.state.warehouses.all[this.$route.query.id].power
    },
    historicalChartTitleText: function () {
      // Get the user preferred/configured time zone
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      // Get the user preferred/configured date format
      const format = `${getDateFormat()} HH:mm z`
      // Convert the timestamps to date format
      const startTime = moment.utc(this.range[0]).tz(tmz).format(format)
      const endTime = moment.utc(this.range[1]).tz(tmz).format(format)
      return `${this.$store.state.warehouses.all[this.$route.query.id].name} [${startTime} to ${endTime}]`
    },
    mapWidthCalc: function () {
      return getMapWidth(this.$parent.windowWidth, 0.23)
    },
    powerChartOptions: function () {
      let title = 'Power Consumption'
      let yAxisFormat = [{name: 'Power (kWh)', ymin: 0}]
      let series = [{
        name: 'Power',
        type: 'bar',
        data: this['warehouses/powerData'](this.$route.query.id, 'power', this.range)
      }]
      const options = {yAxis: yAxisFormat, vMapPieces: ['power'], tooltip: 'datetime', legend: {bottom: 5}}
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      return chartOptionFactory(chartConfig, title, series, options, {}, tmz)
    },
    reportOptions: function () {
      const pageConfig = this.$store.state.configuration.pageOptions.warehouseDetail
      // Detail card options from configuration
      const reportOptions = pageConfig.reports
      if (reportOptions.length === 0) return []
      // const showShockRpt = this.shipment.shock && Object.values(this.shockData).length > 0 // Available if the shipment is shock enabled and have shock records
      // All the available card option listed below. Only the options available in configuration and it's show condition is true will be returned as the output.
      const allReportOptions = {
        warehouseDailyIndoorSummary: {icon: 'report', text: 'Daily Indoor Data', optionArgs: {callbackFn: this.openReportModal, params: 'warehouseDailyIndoorSummaryExport'}, show: true}
      }
      // Loop the each config item. If the config is an object, then overwrite the text value from configuration
      // Else return the config based on the key value
      const mapFn = function (key) {
        if (typeof key === 'object') {
          let column = allReportOptions[key.name]
          column.text = key.text
          return column
        } else {
          return allReportOptions[key]
        }
      }
      const userReports = this.$store.state.user.reports
      // Get the cardoptions from the list and filter based on the show value
      // const filteredOptions = reportOptions.map(mapFn).filter(col => col.show)
      const filteredOptions = reportOptions.map(mapFn).filter(col => col.show && userReports.includes(col.optionArgs.params))
      return Object.values(filteredOptions)
    },
    selectedReport: function () {
      // Return the selected report object for the selected report key
      const config = {...this.$store.state.reports.all[this.reportKey]}
      config.reportKey = this.reportKey
      return config
    },
    warehouseDailyIndoorSummaryExportExternalControls: function () {
      const value = this.$store.state.warehouses.all[this.$route.query.id].name
      // Returns the default values for the warehouse unit data report form
      return {
        warehouse: {type: 'hidden', value}
      }
    },
    ...mapState({
      devices: 'devices',
      gateways: 'gateways',
      alerts: 'alerts'
    }),
    ...mapGetters([
      'warehouses/doorData',
      'warehouses/powerData',
      'warehouses/timeSeries',
      'weather/ambientReadings'
    ])
  },
  created () {
    this.$store.dispatch('reports/initialize')
    const stationId = this.$store.state.warehouses.all[this.$route.query.id].weatherStationId || false
    if (stationId) {
      this.$store.dispatch('weather/populateAmbientWeather', [stationId])
    }
    const warehouse = this.$store.state.warehouses.all[this.$route.query.id]
    const dateLimit = moment().subtract(1, 'months').format('YYYY-MM-DD')
    this.$store.dispatch('alerts/fetchAlerts', {macId: this.$route.query.id, dateLimit})
    this.$store.dispatch('warehouses/fetchTempHumid', this.$route.query.id)
    if (warehouse.doors) {
      this.$store.dispatch('warehouses/fetchDoorData', this.$route.query.id)
    }
    if (warehouse.power) {
      this.$store.dispatch('warehouses/fetchPowerData', this.$route.query.id)
    }
    this.$store.dispatch('warehouses/fetchDHUnitMessages', this.$route.query.id)
  },
  data () {
    const warehouse = this.$store.state.warehouses.all[this.$route.query.id]
    const textMessage = `Warehouse: ${warehouse}</br>Gateway: ${this.$route.query.id}`
    const pageConfig = this.$store.state.configuration.pageOptions.warehouseDetail
    // DH Unit table formatters
    // Define all alarm parsing functions
    const alarmFuncs = {
      allOk: function (data) {
        // Get array of all data elements that start with "Alarm", "Fault" or "Warn"
        // Ex: Alarms2, Faults, Warn 40010, etc.
        // Test that all values are 'OK'
        // If so, return false, otherwise return true
        const regex = /fault|alarm|warn/ig
        const matches = Object.entries(data).filter(e => e[0].match(regex))
        // console.log('MATCHES: ', matches)
        return matches.filter(e => e[1] !== 'OK').length > 0
      }
    }
    // execute the one specified in the 'funcToRun' variable:
    // myFuncs[funcToRun]();
    const vm = this
    const getAlarmIcon = function (index) {
      // Set default return value for icon
      let iconData = {name: 'circle_stroke', color: 'grey'}
      if (typeof index !== 'undefined') {
        // If an index is found, lookup up the function to evaluate the data
        // Execute function to get back true or false and then return the
        // appropriate icon data based on this.
        const unitData = warehouse.dhUnits[index]
        const funcName = unitData.alarm
        if (alarmFuncs[funcName]) {
          const alarmIsActive = alarmFuncs[funcName](unitData.data)
          iconData = alarmIsActive ? {name: 'cancel', color: 'red'} : {name: 'check', color: 'green'}
        }
      }
      // if (unitData && vm[unitData[indicator]]) {
      //   iconData = vm[unitData[indicator]](unitData) ? {name: 'check', color: 'green'} : {name: 'cancel', color: 'red'}
      // }
      return generateIconSVG(vm.$store.state, iconData)
    }
    let nameLink = function (row) {
      // dh-detail
      // return path and query string for router link
      let query = { id: vm.$route.query.id, msgType: row.msgType, pktType: row.pktType, unitIndex: row.unitIndex }
      if (row.index) query.msgIndex = row.index
      return {path: 'dh-detail', query: query}
    }
    const activeMarkers = {}
    const pageMarkers = pageConfig.map && pageConfig.map.markers ? pageConfig.map.markers : []
    pageMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: false}
    })
    // If the name attribute found in the unit, then use it else return '-'
    const nameFormatter = function (name) {
      return name || '-'
    }
    const sliderInterval = [pageConfig.slider.range, pageConfig.slider.start]
    return {
      activeMarkers,
      activeTab: 'alerts',
      pagename: 'Warehouse Details',
      compressorStatus: warehouse['Compressor Status'],
      DHUnitTableCols: [
        {label: 'ID', id: 'id'},
        {label: 'Type', id: 'model', link: nameLink},
        {label: 'Name', id: 'name', formatter: nameFormatter},
        {label: 'Alerts', id: 'unitIndex', formatter: getAlarmIcon}
      ],
      DHUnitTableOptions: {
        paginate: false,
        sortable: false
      },
      doors: warehouse.doors,
      discretes: warehouse.discretes,
      externalControls: {},
      humidity: warehouse.humidity,
      isActive: false,
      legendMarkers: pageConfig.map.markers,
      macId: this.$route.query.id,
      markers: [{
        id: 1,
        icon: 'currentLocationIcon',
        infoText: textMessage,
        position: {
          lat: Number.parseFloat(warehouse.latitude),
          lng: Number.parseFloat(warehouse.longitude)
        }
      }],
      modalIsActive: {
        reportConfig: false,
        reportResults: false
      },
      noBottomMargin: 'margin-bottom:1px;',
      reportKey: '',
      reportData: [],
      reportFileName: '',
      runtimes: warehouse.runtimes,
      range: [moment.utc().valueOf(), moment.utc().valueOf()],
      showAlertMessage: this.$store.state.configuration.siteOptions.enableAlertMessages,
      showLoading: false,
      sliderInterval,
      sliderUnit: pageConfig.slider.scale,
      temperature: warehouse.temperature,
      warehouseName: warehouse.name
    }
  },
  methods: {
    /**
     * Activate the clicked tab and show it's content
     * @param {tabId} String - Clicked tab id of the tab
     * Note: Check current tab is activated or not. If not then active the tab
     * Reset the tab fields while change the tab
     */
    activateTab (tabId) {
      if (this.activeTab !== tabId) {
        this.activeTab = tabId
      }
    },
    indoorSummary () {
      /**
       * Return the value of temperature and humidity (Min, Max, Avg) based on date slider
      **/
      const temperatureSeries = this['warehouses/timeSeries'](this.$route.query.id, 'temperature', this.range) //  Get the temperature data with timestamp and value
      const humiditySeries = this['warehouses/timeSeries'](this.$route.query.id, 'humidity', this.range) //  Get the humidity data with timestamp and value
      const deg = '&deg;F'
      const percent = '%'
      const temperature = temperatureSeries.map(temp => temp[1]) // Get the temperature value from temperatureSeries
      const humidity = humiditySeries.map(hum => hum[1]) // Get the humidity value from humiditySeries
      return [
        {name: 'Min Temp', value: `${Math.min(...temperature)}${deg}`},
        {name: 'Avg Temp', value: `${getRoundedAverage(temperature, 1)}${deg}`},
        {name: 'Max Temp', value: `${Math.max(...temperature)}${deg}`},
        {name: 'Min Humidity', value: `${Math.min(...humidity)}${percent}`},
        {name: 'Avg Humidity', value: `${getRoundedAverage(humidity, 1)}${percent}`},
        {name: 'Max Humidity', value: `${Math.max(...humidity)}${percent}`}
      ]
    },
    openReportModal (reportKey) {
      // Opens the report modal for the incoming report key
      this.reportKey = reportKey
      // Get the external controls for the given report key
      // `${reportKey}ExternalControls` - Computed property assigned for the report key
      this.externalControls = this[`${reportKey}ExternalControls`] ? this[`${reportKey}ExternalControls`] : {}
      this.openModal('reportConfig')
    },
    previewReport (reportData) {
      // Opens the report preview modal
      // Assigns the preview table data from the response for the selected report
      this.reportData = reportData
      this.reportFileName = reportData.fileName
      // Open results modal
      this.modalIsActive.reportResults = true
    },
    tagClass (val) {
      if (['Closed', 'Off', 'Stop', 'Standby', 'Off/Manual'].includes(val)) {
        return 'tag is-medium is-dark'
      } else if (['Active', 'OK', 'On', 'Open', 'Run', 'Ready', 'Running'].includes(val)) {
        return 'tag is-medium is-success'
      } else {
        return 'tag is-medium is-danger'
      }
    },
    updateRange: function (val) {
      this.range = val
    },
    ...mapActions([
    ])
  },
  mixins: [modalWindowManager]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.chart-style {
  height: 225px;
  float: none;
  clear: both;
}

.slider-icon {
  border-left: 2px solid rgba(0, 0, 0, .5);
  border-bottom: 2px solid rgba(0, 0, 0, .5);
}

.btn-left {
  background: #FFF;
}
.btn-right {
  background: #FFF;
}

.tabs ul.noMarginleft {
  margin-left: 0px;
  margin-top: 5px;
}
.text-danger {
  color: #dc3545;
  font-weight: bold;
}
.tabs ul.noMarginleft .is-active a {
  font-weight: bold;
  color: #000;
}
</style>
