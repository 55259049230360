import {
  getMessagesProvider,
  getStaticDataProvider,
  getTableEntriesProvider,
  getQueryProvider,
  stateObjectValuesProvider
} from '../providers'
import {chartOptionFactory, shockChartOptionFactory} from '@/store/factories'
import { exportAsCSV, getDateFormat, getDynamicQueryParams, getUrl, isJSON, stringTemplateParser, round, processShockData } from '@/store/helpers'
import moment from 'moment-timezone'

import Vue from 'vue'

/**
 * @returns the default state object
 */
const getDefaultState = () => {
  return {
    all: {}
  }
}

// initial state
const state = getDefaultState()

// // Helper functions to be used internally....
// const getReportRequestList = ({config, controlValues}) => {
// getPayload - Used to prepare the query / table / state object payload based on the section from the report configuration
const getPayload = ({config, controlValues} = {}) => {
  // Split configuration into datasource and sectionConfig (i.e. the rest of the parameters)
  const {dataSource, ...sectionConfig} = config
  const payload = {dataSource: {...dataSource}, sectionConfig}
  const provider = payload.dataSource.provider
  // The complex Configuration is used to split and assign the control values when the field value is in complex format like 00_1B_AD_00_0C_0C_07_20|52|48|1.
  // If the seperator is available in the the config then process the value. In the above example, the seperator is '|'
  // skipcq:JS-W1044 - Skip Logical operator can be refactored to optional chain
  if (sectionConfig.complexConfig && sectionConfig.complexConfig.separator) {
    const separatedValues = controlValues[sectionConfig.complexConfig.separatorField].split(sectionConfig.complexConfig.separator)
    for (const idx in sectionConfig.complexConfig.separatedFieldKeys) {
      if (sectionConfig.complexConfig.separatedFieldKeys[idx]) {
        controlValues[sectionConfig.complexConfig.separatedFieldKeys[idx]] = separatedValues[idx]
      }
    }
  }
  // Substitute for %text% wildcards in any parameter of the payload object from the report definition.
  // Substitution values come from input list and/or iteration calculation.
  Object.entries(payload.dataSource).forEach(([key, val]) => {
    if (typeof val === 'string') {
      const newVal = val.replace(/%([\w]+)%/g, (m, n) => {
        // if match is contained in control values, substitued it, otherwise return same value
        return controlValues[n] || m
      })
      // dataSource[key] = newVal
      payload.dataSource[key] = newVal
    }
  })
  if (provider === 'table') {
    // Table config is good as is....
    //
    // // payload.tableName = dataSource.table
    // // payload.where = dataSource.whereClause
    // // payload.sort = dataSource.sort
    // // If fields are not specified, send empty list for fields.
    // if (!payload.fields) payload.fields []
  } else if (provider === 'query') {
    // payload.query = dataSource.query
    // Map query parameters using the 'controlList' from the section as keys and values from 'controlValues'.
    const queryParameters = payload.dataSource.queryParams || []
    // payload.dataSource.params = queryParameters.map(key => controlValues[key])
    payload.dataSource.params = getDynamicQueryParams(queryParameters, controlValues)
  } else if (provider === 'state') {
    // Map the portion of state that is to be used, substituting any wildcard values for their actual
    // // value from controlValues.
    // const config = {...payload}
    // const srcObj = dataSource.sourceObject.replace(/%([^%]+)%/g, (match) => {
    //   // If there's a replacement for the key, return that replacement. Otherwise, return a empty string.
    //   const val = match.replace(/%/g, '')
    //   return controlValues[val]
    // })
    // config.sourceObject = srcObj
    // payload.config = config
  } else if (provider === 'message') {
    //  TO-DO -> Make this more obvious....
    const msgControlValues = controlValues
    // if (reportConfig.dataSource.messageConfig && reportConfig.dataSource.messageConfig.separator) {
    //   const separatedValues = msgControlValues[reportConfig.dataSource.messageConfig.separatorField].split(reportConfig.dataSource.messageConfig.separator)
    //   for (const idx in reportConfig.dataSource.messageConfig.separatedFieldKeys) {
    //     msgControlValues[reportConfig.dataSource.messageConfig.separatedFieldKeys[idx]] = separatedValues[idx]
    //   }
    // }
    // const { provider, ...payload } = reportConfig.dataSource
    // payload.payload = dataSource
    payload.params = msgControlValues
  }
  return payload
}

// getters
const getters = {}

// actions
const actions = {
  // Helper functions to be used internally....
  async buildRequestList ({dispatch}, {config, controlValues}) {
    const sectionData = config.sections
    // Build list of requests from sections defintion
    // requestList - Will be the collection of the query, table request to be passed to the Promise all to collect the response
    const requestList = []
    // Loop through sections n times, one for each iteration.  Iterator value is added to control values so it is avaiable for
    // use in any of the sections.
    // loadSection - Will load te given sections. If the section type is
    // an iterator, then it will load the data and iterate value and load the sub sections
    // If any child section have an iterator, then it loads that too
    const loadSection = async function (section, controlValues) {
      if (section.type === 'iterator') {
        const iterations = await dispatch('buildIteratorList', {config: section, controlValues})
        // Loop the iteration and load the section data
        console.debug('iterations: ', iterations)
        for (let idx = 0; idx < iterations.length; idx++) {
          const iter = iterations[idx]
          const allControls = {...controlValues, ...iter}
          console.debug('sections: ', section.sections)
          for (let secIdx = 0; secIdx < section.sections.length; secIdx++) {
            const childSection = section.sections[secIdx]
            await loadSection(childSection, allControls)
          }
        }
      } else {
        // Get payload using section config and merged controls and then fetch data...
        const payload = getPayload({config: section, controlValues})
        requestList.push(dispatch('fetchReportData', payload))
      }
    }
    // Loop the section and load the section data
    console.debug('section Data: ', sectionData)
    for (let idx = 0; idx < sectionData.length; idx++) {
      const section = sectionData[idx]
      await loadSection(section, controlValues)
    }
    return requestList
  },
  /**
   * Retrieves a list of values to iterate secions over.<br>
   * Uses 'getPayload' to do data retrival.<br>
   * - If successful, returns list<br>
   * - If error, log to console & returns error code.
   * @param {Object} state - Vuex state
   * @param {Object} config - configuration of the iterator (i.e. section)
   * @param {Object} controlValues  - all inputs to the report avaiable to iterator as required
   */
  async buildIteratorList ({dispatch}, {config, controlValues}) {
    // Get payload for data search....
    const payload = getPayload({config, controlValues})
    // const iterField = dataSource.iterField[0]
    const iterMap = val => {
      const obj = {}
      Object.values(val).forEach((v, i) => {
        obj[`iter${i}`] = v
      })
      return obj
    }
    const iterations = await dispatch('fetchReportData', payload)
    // fetchReportData returns an object with keys  0, 1, 2.... in the data parameter
    // these need to be converted into a list of objects with the structure
    // {iter0: val[0], iter1: val[1], iter2: val[2], ...} for as many fields as are returned
    // Use map to build the list
    // filterConfigFlds - If config.dataSource.fields && config.dataSource.fields.length > 0, The map the configured fields
    // in the object, else will return the row as it is
    const filterConfigFlds = function (row) {
      console.debug('config.dataSource.fields: ', config.dataSource.fields)
      const flteredRes = config.dataSource.fields && config.dataSource.fields.length > 0 ? config.dataSource.fields.map(fld => row[fld]) : row
      return flteredRes
    }
    const iterationList = Object.values(iterations.data).map(filterConfigFlds).map(iterMap)
    // TO-DO - Return list of lists....with multiple iterators in each.
    return iterationList
  },

  /**
   * Retrieves and returns results for a pre-configured query.<br>
   * - If successful, returns query payload<br>
   * - If error, log to console & returns error code.
   * @param {Object} state - Vuex state
   * @param {Object} queryArgs - Arguments to be sent to query provider
   * @param {String} queryArgs.query  - Name of stored query
   * @param {Array} queryArgs.params  - List of query parameters
   */
  fetchReportData: ({ rootState }, queryArgs) => {
    let providerFn = ''
    const providerPayload = queryArgs.dataSource
    switch (queryArgs.dataSource.provider) {
      case 'table':
        providerFn = getTableEntriesProvider(rootState, providerPayload)
        break
      case 'query':
        providerFn = getQueryProvider(rootState, providerPayload)
        break
      case 'message':
        providerFn = getMessagesProvider(rootState, providerPayload)
        break
      case 'state':
        providerFn = stateObjectValuesProvider(rootState, providerPayload)
        break
      default:
        providerFn = getQueryProvider(rootState, providerPayload)
        break
    }
    // let provider = (queryArgs.type === 'table') ? getTableEntriesProvider(state, queryArgs) : getQueryProvider(state, queryArgs)
    return new Promise((resolve, reject) => {
      providerFn.then(response => {
        const pick = function (obj, props) {
          // Make sure object and properties are provided
          if (!obj || !props) return {}
          // Create new object
          const picked = {}
          // Loop through props and push to new object
          props.forEach((prop) => {
            picked[prop] = obj[prop]
          })
          // Return new object
          return picked
        }
        const tmzn = rootState.user.timezone ? rootState.user.timezone : moment.tz.guess()
        // formattedResponse
        const dateFmt = `${getDateFormat()} HH:mm z`
        const dateFmtShrt = `${getDateFormat('short')} HH:mm`
        const format = function (obj, formatters, fields = []) {
          // console.log('formattedResponse: ', obj, formatters)
          // If the field configuration found in the report, then use the mentioned fields only, instead of all the fields in the object
          console.debug('fields: ', fields)
          const keyList = fields.length > 0 ? fields : Object.keys(obj)
          const alertEvents = rootState.configuration.alertEvent
          // skipcq: JS-0044 - Cyclomatic complexity more than allowed
          keyList.forEach((key, indx) => {
            if (formatters[indx]) {
              if (formatters[indx] === 'round') {
                obj[key] = obj[key] ? Number(obj[key]).toFixed(2) : '-'
              } else if (formatters[indx] === 'date') {
                // skipcq JS-0049: Avoid square-bracket notation
                const isMoment = moment(obj[key], 'ddd MMM D HH:mm:ss YYYY')['_isValid']
                const value = moment.utc(obj[key], 'ddd MMM D HH:mm:ss YYYY').tz(tmzn).format(dateFmt)
                obj[key] = obj[key] && isMoment ?  value : '-'
              }  else if (formatters[indx] === 'timestampToDate') {
                obj[key] = obj[key] ? moment.utc(obj[key] * 1000).tz(tmzn).format(dateFmtShrt) : '-'
              } else if (formatters[indx] === 'timestamp') {
                obj[key] = obj[key] ? moment.utc(obj[key], 'ddd MMM D HH:mm:ss YYYY').format('X') : '-'
              } else if (formatters[indx] === 'shpLink') {
                obj[key] = obj[key] ? `<a href="${getUrl(`/detail?id=${obj[key]}&mode=shipment`)}">${obj[key].split('-')[0]}</a>` : '-'
              } else if (formatters[indx] === 'alertName') {
                obj[key] = obj[key] && alertEvents[obj[key]] ? alertEvents[obj[key]].name : obj[key]
              } else if (formatters[indx] === 'shockUnit') {
                obj[key] = obj[key] ? `${obj[key]} (g)` : obj[key]
              } else if (formatters[indx] === 'percentageSuffix') {
                obj[key] = obj[key] ? `${obj[key]} %` : obj[key]
              } else if (formatters[indx] === 'temperature') {
                let value = obj[key]
                if (obj[key] && Number(obj[key]) && Number(obj[key]) > 0) {
                  const isC = rootState.user.temperatureUnit === 'Celsius'
                  const unit = isC ? ' \xB0C' : ' \xB0F'
                  value = isC ? round((Number(value) - 32) * 5 / 9, 1) : value
                  value = `${value} ${unit}`
                }
                obj[key] = value
              }
            }
          })
          return obj
        }
        // Add section config for later reference in the processing of the item.  Also
        // include the datasource values for access to fitlers, formatters, fields etc.
        response.sectionConfig = queryArgs.sectionConfig
        response.sectionConfig.dataSource = queryArgs.dataSource
        let responseData = response.data
        // If the response is coming the formatted sql function, we need to convert it into JSON object and return the value array
        if (queryArgs.dataSource.provider === 'query' && (queryArgs.dataSource.query === 'formattedGuidMapList' || queryArgs.dataSource.query === 'formattedGuidMap' || queryArgs.dataSource.query === 'shipmentMapObject' || queryArgs.dataSource.query === 'zoneTime')) {
          if (isJSON(Object.values(responseData[0])[0])) {
            const parsedData = JSON.parse(Object.values(responseData[0])[0])
            responseData = queryArgs.dataSource.query === 'formattedGuidMapList' ? parsedData : [parsedData]
          }
          if (queryArgs.dataSource.query === 'zoneTime') {
            responseData = Object.values(responseData[0])[0].split('|').map(r => r.split(':'))
          }
        }
        // If fields are provided, filter the list down to just those fields.
        if (queryArgs.dataSource.fields) {
          const retFields = queryArgs.dataSource.fields
          // const fields = queryArgs.reportConfig.fields
          // Create new object with destructuring with only the fields provided
          const filteredData = {}
          Object.entries(responseData).forEach(([key, value]) => {
            // console.log('filteredResponse: key val', key, value, retFields)
            filteredData[key] = pick(value, retFields)
          })
          responseData = filteredData
        }
        // If formatters are provided, use them to transform any required values..
        if (queryArgs.dataSource.formatters) {
          const formatters = queryArgs.dataSource.formatters
          // const fields = queryArgs.reportConfig.fields
          // Create new object with destructuring with only the fields provided
          const formattedData = {}
          // If the field configuration is found the report, the use the fields instead of all the fields in the object
          const fields = queryArgs.dataSource.fields ? queryArgs.dataSource.fields : []
          Object.entries(responseData).forEach(([key, value]) => {
            formattedData[key] = format(value, formatters, fields)
          })
          responseData = formattedData
        }
        response.data = responseData
        resolve(response)
      }).catch(e => {
        reject(e)
      })
    })
  },
  /**
   * Looks up all report definitions from API and add objects to the Vuex store.<br>
   * Retrives information using {@link getStaticDataProvider}.<br>
   * @param {boolean} force - indication if configuration should be reloaded regardless of current state
   * @returns {booean} Indication of configuration load
   */
  initialize ({commit, rootState}, {force = false} = {}) {
    // If not force and config already loaded, do not execute the function and initialize again.
    if (!force && rootState.initialized.includes('reports')) {
      return
    }
    /**
     * Import configuration values from jsonconfig file - this will return all reports.  Add report to config
     *  if the config is in the users report list.
     */
    getStaticDataProvider(rootState, {path: 'reports'}).then(response => {
      // console.log('ALL USERS REPORTS: ', rootState.user.reports)
      Object.entries(response).forEach(report => {
        // console.log('reportConfig', report)
        // only add reports defined for the user....
        if (rootState.company.reports.includes(report[0])) commit('SET_REPORT', report)
      })
      // Add to intialized objects so inialization is tracked and config is reset on logout
      commit('setInitializedModule', 'reports', { root: true })
    })
  },
  /**
   * Main report generation function.  Performs the following:<br>
   * - calls buildRequestList to get list of data requests for each section.<br>
   * - calls promise list to fetch all data<br>
   * - builds data object by formatting each section as appropriate.<br>
   * - calls function to generate csv or pdf.
   * @param {Object} controlValues - All of the formatted input parameters
   * @param {Boolean} preview - Defines the current request is for preview or download
   * @param {String} reportKey -The selected report key to get the report config
   * @param {String} fileName - Defines the current request is for preview or download
   * @param {String} fileFormat - Defines the current request is for preview or download
   * @param {String} queryArgs.query  - Name of stored query
   * @param {Array} queryArgs.params  - List of query parameters
   */
  async runReport ({dispatch, state, rootState}, {controlValues, preview = false, reportKey = '', fileName = '', fileFormat = 'pdf'} = {}) {
    // Configuration for the given report
    const config = {...state.all[reportKey]}
    // Add fileName & fileFormat to the configuration
    config.fileName = fileName
    config.fileFormat = fileFormat
    // Get request list
    const requestList = await dispatch('buildRequestList', {config, controlValues})
    return new Promise((resolve, reject) => {
      // Call report results providers and gather data
      const reportData = []
      let emptyReports = 0
      const chartConfig = rootState.configuration.charts
      Promise.all(requestList).then(responses => {
        // skipcq JS-0044 : Avoid Cyclomatic complexity more than allowed
        responses.forEach((response, resIndex) => {
          let responseData = response.data
          let noRecordFound = false
          // Collect the empty response request count
          console.debug('responseData:', responseData)
          if (Object.values(responseData).length === 0) {
            emptyReports++
            noRecordFound = true
          }
          // console.log('response', response)
          const sectionConfig = response.sectionConfig
          const type = response.sectionConfig.type
          // If section type = content the output will be content configured in the report. If response is empty, the noRecordMsg configured in report will be passed as the content.
          if (type !== 'shockRecords' && (type === 'content' || noRecordFound)) {
            const contentData = {
              'title': sectionConfig.title,
              'pageBreak': sectionConfig.pageBreak,
              'content': sectionConfig.type === 'content' ? sectionConfig.content : sectionConfig.noRecordMsg,
              'background': '#003366',
              'color': '#fff',
              'type': 'html',
              'isContent': true
            }
            if (sectionConfig.style) contentData.style = sectionConfig.style
            if (sectionConfig['break-before']) contentData['break-before'] = true
            if (sectionConfig['break-after']) contentData['break-after'] = true
            reportData.push(contentData)
            return
          }
          if (type === 'label') {
            const labelContent = stringTemplateParser(sectionConfig.content, responseData[0])
            const labelData = {
              'pageBreak': sectionConfig.pageBreak,
              'content': labelContent,
              type
            }
            if (sectionConfig.style) labelData.style = sectionConfig.style
            if (sectionConfig['break-before']) labelData['break-before'] = true
            if (sectionConfig['break-after']) labelData['break-after'] = true
            reportData.push(labelData)
            return
          }
          // Section type = list
          if (type === 'list') {
            const listData = []
            let rowRecords = []
            let indexCounter = 0
            // Loop the response item and break / create new row item
            // when the indexCounter value match the listSpanCount value configured in the report section.
            Object.values(responseData).forEach((row) => {
              // Labels are either send explicitly in 'labels' parameter from config or implicit in keys of
              // returned data object
              // Note: consider moving labels to datasource, but will have to deal with datasource being
              // stripped out of section config object in 'getPayload'
              // skipcq:JS-W1044 - Skip Logical operator can be refactored to optional chain
              const keys = sectionConfig.dataSource && sectionConfig.dataSource.labels ? sectionConfig.dataSource.labels : Object.keys(row)
              // skipcq:JS-W1044 - Skip Logical operator can be refactored to optional chain
              const values = sectionConfig.dataSource && sectionConfig.dataSource.fields ? sectionConfig.dataSource.fields.map(fld => row[fld]) : Object.values(row)
              // const values = Object.values(row)
              values.forEach((value, index) => {
                indexCounter++
                rowRecords.push(`${keys[index]}: `)
                rowRecords.push(value)
                const modValue = indexCounter % sectionConfig.listSpanCount
                // If the mode value is = 0 or the item is current item is the last
                // Then add the row records into the list data
                console.debug('values: ', values)
                if (modValue === 0 || indexCounter === values.length) {
                  listData.push(rowRecords)
                  rowRecords = []
                }
              })
            })
            const tableData = {
              'title': sectionConfig.title,
              'pageBreak': sectionConfig.pageBreak,
              'headers': [],
              'rows': listData,
              type,
              'isList': true
            }
            if (sectionConfig.style) tableData.style = sectionConfig.style
            if (sectionConfig['break-before']) tableData['break-before'] = true
            if (sectionConfig['break-after']) tableData['break-after'] = true
            reportData.push(tableData)
            return
          }
          if (type === 'map') {
            const legendConfig = rootState.configuration.mapLegendIcons
            // const iconPath = 'https://cgvak.intelyt.net/img/'
            const iconPath = getUrl('/static/icons/')
            let locData = Object.values(responseData)
            /* Get the icon attributes for the given icon name */
            const getIconConfig = function (iconName) {
              if (!iconName) return
              switch (iconName) {
                case 'alert':
                  // skipcq JS-0045: all code paths should have explicit returns
                  return `markers=icon:${iconPath}alert.png|size:tiny`
                case 'origin':
                  // skipcq JS-0045: all code paths should have explicit returns
                  return `markers=icon:${iconPath}origin.png|size:tiny`
                case 'destination':
                  // skipcq JS-0045: all code paths should have explicit returns
                  return `markers=icon:${iconPath}destination.png|size:tiny`
                case 'currentLocation':
                  // skipcq JS-0045: all code paths should have explicit returns
                  return `markers=icon:${iconPath}currentLocation.png|size:tiny`
                case 'gps':
                  // skipcq JS-0045: all code paths should have explicit returns
                  return 'markers=color:0x224290|label:G|size:tiny'
                case 'igate':
                  // skipcq JS-0045: all code paths should have explicit returns
                  return 'markers=color:0xFF8C00|label:I|size:tiny'
                case 'cell':
                default:
                  // skipcq JS-0045: all code paths should have explicit returns
                  return 'markers=color:0x008000|label:C|size:tiny'
              }
            }
            let mapDetails = ''
            const additionalMarkers = []
            // Load the location data from state
            if (sectionConfig.dataSource.provider === 'state') {
              locData = rootState
              // Split and loop through item to define the destination object
              sectionConfig.dataSource.sourceObject.split('.').forEach(obj => {
                locData = locData[obj]
              })
              locData = [...locData]
              const path = locData.map(l => `${Number(l.latitude)}, ${Number(l.longitude)}`).join('|')
              // Group the location data
              const locGroup = locData.reduce((group, loc) => {
                const { locationType } = loc;
                group[locationType] = group[locationType] || [];
                group[locationType].push(loc);
                return group;
              }, {});
              /* locMapFn - Map the markers for the given location type */
              const locMapFn = function (locType) {
                const color = legendConfig[locType] ? legendConfig[locType].color : 'red'
                const label = locType[0]
                const markerStr = `markers=color:${color}|label:${label}|size:tiny`
                const markerPoints = locGroup[locType].map(l => `${Number(l.latitude)}, ${Number(l.longitude)}`).join('|')
                return `${markerStr}|${markerPoints}`
              }
              mapDetails = Object.keys(locGroup).map(locMapFn).join('&')
              mapDetails = `${mapDetails}&path=${path}`
              additionalMarkers.push(mapDetails)
            } else {
              Object.values(responseData).forEach((row) => {
                // Markers from the response
                const markers = row.markers
                //
                const pathList = row.paths.complete
                Object.keys(markers).forEach((locType) => {
                  console.debug('markers: ', markers[locType], locType)
                  if (markers[locType].length === 0) return
                  // const iconName = index > 4 ? 'cell' : locType
                  const markerPrefix = getIconConfig(locType)
                  mapDetails += `${markerPrefix}|${markers[locType]}&`
                })
                console.debug('pathList: ', pathList)
                mapDetails += pathList.length > 0 ? `path=color:0xFF0000|weight:1|${pathList}` : ''
                additionalMarkers.push(mapDetails)
                const additionalMapConfig = sectionConfig.additionalMaps ? Object.keys(sectionConfig.additionalMaps) : []
                // If origin and destination map is configured in configuration, then add in the map
                if (additionalMapConfig && additionalMapConfig.length > 0) {
                  additionalMapConfig.forEach((mrkType) => {
                    if (!markers[mrkType]) return
                    // skipcq:JS-W1043 - Skip redundant literal in a logical expression
                    const zoom = sectionConfig.additionalMaps[mrkType].zoom || 14
                    const mapStr = `${mapDetails}&center=${markers[mrkType]}&zoom=${zoom}`
                    additionalMarkers.push(mapStr)
                  })
                }
              })
            }
            const mapData = {
              'title': sectionConfig.title,
              'description': sectionConfig.description,
              'pageBreak': sectionConfig.pageBreak,
              layout: 'grid',
              type,
              'isMap': true
            }
            // Add the map list to the map payload
            additionalMarkers.forEach((map, idx) => {
              const mapIndx = `map${idx+1}`
              mapData[mapIndx] = map
            })
            if (sectionConfig.style) mapData.style = sectionConfig.style
            if (sectionConfig['break-before']) mapData['break-before'] = true
            if (sectionConfig['break-after']) mapData['break-after'] = true
            reportData.push(mapData)
            return
          }
          // If the type is shock records, then construct the shock detail list
          // shock chart and table data inside this type
          if (type === 'shockRecords') {
            const tmzn = rootState.user.timezone ? rootState.user.timezone : moment.tz.guess()
            // formattedResponse
            const dateFmt = `${getDateFormat('short')} HH:mm:ss`
            // Loop through the each data and build the list, chart and table data
            Object.values(responseData).forEach((rsp) => {
              const notes = isJSON(rsp.notes) ? JSON.parse(rsp.notes) : false
              if (!notes) return
              const rows = [
                ['Event Time (UTC): ', moment.utc(notes.timestamp * 1000).tz(tmzn).format(dateFmt)],
                ['Device: ', rsp.macId],
                ['Max Shock [x,y,z] (g): ', notes.maxAcc.join(', ')],
                ['DeltaV [x,y,x] (m/s): ', notes.dv.join(', ')],
                ['Frequency (Hz): ', notes.frequency],
                ['Duration: ', notes.duration],
                ['Points: ', notes.points]
              ]
              const listData = {
                title: 'Shock Event Summary',
                rows,
                type: 'list',
                isList: true,
                style: 'width: 47%;page-break-inside: avoid;clear:both;'
              }
              reportData.push(listData)
              const shockData = processShockData('', rsp.notes)
              /* RMS calculation */
              /* const rmsCalc = (x, y, z) => Math.round(Math.sqrt((x * x) + (y * y) + (z * z), 2))
              const rms = new Array(31).fill(0)
              // const sampleRate = 14
              // 1000 * ( counter / (3200 / 2 ** (15- sampRate)) where counter = 0-31
              const xAxisIdx = [...rms]
              const axisData = xAxisIdx.map((val, idx) => 1000 * ( idx / notes.frequency))
              // const axisData = xAxisIdx.map((val, idx) => 1000 * ( idx / (3200 / 2 ** (15 - sampleRate))))
              // shockData.timestamp = response['Device Time (UTC)']
              const mapFn = function (itm) {
                return [axisData[Number(itm[0])], parseFloat(itm[1])]
              }
              const idxMap = ['_0', '_1', '_2']
              const axisMap = ['X', 'Y', 'Z']
              const shockRsp = idxMap.map(axs => notes.acc[axs])
              // Loop throughthe shock data and create axis and row records
              shockRsp.forEach((val, idx) => {
                // Return list of values for the shock points or empty array if the axis value is not found
                const readings = val ? Object.entries(val).map(mapFn) : new Array(31).fill(0)
                const axis = axisMap[idx]
                // t1Rows.push([axis, ...Object.values(val).slice(0, 15)])
                // t2Rows.push([axis, ...Object.values(val).slice(15)])
                shockData[axis] = readings
              })
              shockData.timestamp = notes.timestamp
              // rms.map(() => {})
              const rmsMap = function (val, idx) {
                return [axisData[idx], rmsCalc(shockData.X[idx][1], shockData.Y[idx][1], shockData.Z[idx][1])]
              }
              if (shockData.X && shockData.Y && shockData.Z) {
                shockData.RMS = rms.map(rmsMap)
              } */
              const getChartOptions = shockChartOptionFactory(shockData, '', false, chartConfig, true)
              getChartOptions.grid = { show: false }
              if (!getChartOptions.grid) getChartOptions.grid = {left: 40, right: 20}
              // const date = new Date()
              // const timestamp = date.getTime()
              console.debug('reportData: ', reportData)
              const chartId = `chart-${reportData.length}`
              const chartObject = {
                // 'title': sectionConfig.title,
                'title': '',
                'chartOption': JSON.stringify(getChartOptions),
                chartId,
                'isChart': true,
                'style': 'width:47%;height:285px;page-break-inside: avoid;',
                'type': 'chart'
              }
              reportData.push(chartObject)
              /* const t1Data = {
                type: 'table',
                headers: t1Headers,
                rows: t1Rows,
                isTable: true
              }
              reportData.push(t1Data)
              const t2Data = {
                type: 'table',
                headers: t2Headers,
                rows: t2Rows,
                isTable: true
              }
              reportData.push(t2Data) */
            })
          }
          if (type === 'table') {
            let headers = (responseData[0]) ? Object.keys(responseData[0]) : []
            // If tableHeaders configured in the report section then use it as the header
            // skipcq:JS-W1044 - Skip Logical operator can be refactored to optional chain
            if (sectionConfig.dataSource && sectionConfig.dataSource.labels) {
              headers = sectionConfig.dataSource.labels
            }
            const rows = []
            let rowData = responseData
            // if tableCols configured in the report, use the columns alone in the order mentioned in the report config
            // skipcq:JS-W1044 - Skip Logical operator can be refactored to optional chain
            if (sectionConfig.dataSource && sectionConfig.dataSource.fields) {
              const tableCols = sectionConfig.dataSource.fields
              Object.values(responseData).forEach((row) => {
                const rowVal = tableCols.map(col => row[col])
                rows.push(rowVal)
              })
              console.debug('rows: ', rows)
              rowData = rows.length > 0 ? rows : rowData
            }
            const tableData = {
              'title': sectionConfig.title,
              'description': sectionConfig.description,
              'pageBreak': sectionConfig.pageBreak,
              headers,
              type,
              'rows': rowData,
              'isTable': true
            }
            if (sectionConfig.style) tableData.style = sectionConfig.style
            if (sectionConfig.class) tableData.class = sectionConfig.class
            if (sectionConfig['break-before']) tableData['break-before'] = true
            if (sectionConfig['break-after']) tableData['break-after'] = true
            reportData.push(tableData)
            return
          }
          if (type === 'chart') {
            let getChartOptions = {}
            if (sectionConfig.chartType === 'heatMapChart') {
              // Get the chart options
              getChartOptions = JSON.parse(JSON.stringify(sectionConfig.chartOptions))
              // Get the Y Axis data from source time
              let sourceTimeList = Object.values(responseData).map(data => data.sourceTime)
              // Remove the duplicate dates
              sourceTimeList = [...new Set(sourceTimeList)]
              // Prepare the series data value here
              const seriesData = Object.values(responseData).map(data => {
                const arrData = data.data.replace('[', '').replace(']', '').split(',').map(x => parseInt(x, 10))
                // Get the index value for the date to match the chart item position
                const index = sourceTimeList.indexOf(data.sourceTime)
                if (index !== -1) arrData[1] = index
                return {value: arrData}
              })
              // Replace the y axis and series data in the configuration
              getChartOptions.yAxis.data = sourceTimeList
              getChartOptions.series[0].data = seriesData
            }
            if (sectionConfig.chartType === 'pieChart') {
              // Get the chart options
              getChartOptions = JSON.parse(JSON.stringify(sectionConfig.chartOptions))
              // Prepare the series data value here
              getChartOptions.series[0].data = Object.values(responseData)
            }
            if (sectionConfig.chartType === 'lineChart') {
              const chartOptions = JSON.parse(JSON.stringify(sectionConfig.chartOptions))
              const xAxisData = []
              const series = {...chartOptions.seriesConfig}
              const tmz = rootState.user.timezone ? rootState.user.timezone : moment.tz.guess()
              Object.values(responseData).forEach((row) => {
                for (const [key, value] of Object.entries(row)) {
                  // If the xAxisDataCol mentioned in the report config, then store the value in the xAxisData []
                  // skipcq:JS-W1044 - Skip Logical operator can be refactored to optional chain
                  if (chartOptions.xAxisDataCol && chartOptions.xAxisDataCol.includes(key)) {
                    let axisVal = value
                    if (moment.isMoment(moment(axisVal))) {
                      axisVal = moment(axisVal).tz(tmz).format(`${getDateFormat('short')} HH:MM`)
                    }
                    xAxisData.push(axisVal)
                  }
                  // If the series is configured, then store the series data in the series section for the current key column
                  if (series[key]) {
                    if (!series[key].data) {
                      series[key].data = []
                    }
                    let val = value
                    // If the column have a separator value, then split the value using the separator
                    if (series[key].separator) {
                      val = val.split(series[key].separator)
                    }
                    series[key].data.push(val)
                  }
                }
              })
              chartOptions.xAxis.data = xAxisData
              getChartOptions = chartOptionFactory(chartConfig, sectionConfig.title, Object.values(series), chartOptions, {}, tmz)
            }
            if (sectionConfig.chartType === 'shockChart') {
              const showTitle = false
              getChartOptions = shockChartOptionFactory(responseData, controlValues.deviceMacId, showTitle, chartConfig)
              getChartOptions.grid = { show: false }
            }
            if (!getChartOptions.grid) getChartOptions.grid = {left: 40, right: 20}
            const date = new Date()
            const timestamp = date.getTime()
            const chartObject = {
              'title': sectionConfig.title,
              'description': sectionConfig.description,
              'pageBreak': sectionConfig.pageBreak,
              'chartOption': JSON.stringify(getChartOptions),
              'chartId': timestamp + resIndex,
              'isChart': true,
              type
            }
            if (sectionConfig.style) chartObject.style = sectionConfig.style
            if (sectionConfig['break-before']) chartObject['break-before'] = true
            if (sectionConfig['break-after']) chartObject['break-after'] = true
            reportData.push(chartObject)
          }
        })
        // If all the sections have empty response, the do not download the report and pass the notify message
        console.debug('reportData: ', reportData)
        if (reportData.length === emptyReports) {
          resolve({msg: 'No records found for the given filters.'})
          // vm.notifyMsg = 'No records found for the given filters.'
          // vm.disableDwnBtn = false
          return
        }
        // If title configured with the wild card value, the replace the wild card with the control value
        // const regex = /%[\w]+%/g
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        let title = config.fileName || ''
        // Replace the report params with value in title
        if (config.title) title = stringTemplateParser(config.title, controlValues)
        if (config.fileFormat === 'csv') {
          // If selection is 'csv' call function to export csv data
          const csvResp = {close: true}
          // If it is a download request, then format and download the file
          if (!preview) {
            const data = []
            Object.values(reportData[0].rows).forEach((row, index) => {
              data[index] = Object.values(row).map(string => { return (!string || string === null) ? '' : string.replace(/"/g, '""').replace(/,/g, ';').replace(/\n{1,2}/g, ' | ') }).join(',')
            })
            exportAsCSV(reportData[0].headers, data, config.fileName)
          } else {
            // If it is a preview request send the table data and the file name
            csvResp.data = reportData[0].rows
            csvResp.headers = reportData[0].headers
            csvResp.fileName = config.fileName
          }
          // setTimeout(function () {
          //   vm.notifyMsg = ''
          //   vm.disableDwnBtn = false
          //   vm.$emit('close')
          // }, 2000)
          resolve(csvResp)
        } else {
          // Otherwise, generate PDF file.....
          controlValues.timeZone = rootState.user.timezone ? rootState.user.timezone : 'Etc/GMT'
          controlValues.timeZoneShort = moment().tz(controlValues.timeZone).format('z')
          const logo = rootState.company.reportLogo && rootState.company.reportLogo.length > 0 ? rootState.company.reportLogo : false
          const payloadObj = {
            template: config.outputs.pdf,
            data: {
              title,
              logo,
              // skipcq:JS-W1043 - Skip redundant literal in a logical expression
              styleSheet: config.styleSheet || '',
              reportData,
              'queryInput': controlValues
            },
            moreData: {fileName: config.fileName, preview}
          }
          resolve({generate: true, data: payloadObj})
        }
      }).catch(e => {
        // vm.notifyMsg = ''
        // vm.disableDwnBtn = false
        reject(e)
      })
    })
  }
  //   })
  // }
}

// mutations
const mutations = {
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  /**
   * Resets object state to initial values set upon creation.
   */
  SET_REPORT (state, report) {
    Vue.set(state.all, report[0], report[1])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
