<template>
  <div>
    <level-bar>{{name}}</level-bar>
      <div class="columns">
        <div class="column">
          <intelyt-card>
            <template slot="header">On Demand Reports</template>
            <base-table v-if="userReports.length > 0"
              :data="userReports"
              :columns="dynamicReportColumns"
              :options="reportTableOptions"
            >
            </base-table>
          </intelyt-card>
        </div>
      </div>
    <report-config-modal
      :isActive="modalIsActive.reportConfig"
      :selectedReport="selectedReport"
      v-on:generate="runReport"
      v-on:close="closeModal('reportConfig')"
    >
    </report-config-modal>
    <report-results-modal
      :reportData="reportData"
      :reportSearch="reportSearch"
      :fileName="fileName"
      :isActive="modalIsActive.reportResults"
      v-on:close="closeModal('reportResults')"
    >
    </report-results-modal>
  </div>
</template>

<script>
import { modalWindowManager } from '@/store/mixins'
import { mapState } from 'vuex'

export default {
  components: {
  },
  computed: {
    userReports () {
      const usrReports = this.$store.state.user.reports || []
      const allReports = this.$store.state.reports.all || {}
      // Filter the reports only available for the current user
      // and global reports only. Not the detail page reports
      return usrReports.filter(x => typeof allReports[x] !== 'undefined' && !allReports[x].detailPageReport).map(rep => {
        const report = allReports[rep]
        // Pass the report key to get the report config in the reports module
        report.reportKey = rep
        return report
      })
    },
    ...mapState({})
  },
  created () {
    this.$store.dispatch('reports/initialize')
    this.$store.dispatch('geofences/getZoneList')
    if (typeof this.$store.state.locations.all === 'undefined' || Object.values(this.$store.state.locations.all).length === 0) {
      // Initializing location list in state, if not found
      this.$store.dispatch('locations/initializeAll')
    }
  },
  data () {
    const showCsvIcon = function (report) {
      const key = Object.keys(report.outputs).join('-')
      return key === 'csv'
    }
    const showPdfIcon = function (report) {
      const key = Object.keys(report.outputs).join('-')
      return key === 'pdf'
    }
    const showReportIcon = function (report) {
      return Object.keys(report.outputs).length > 1
    }
    const controlIcons = [
      {icon: 'download_csv', callback: this.configureReport, tooltip: 'Configure & Run Report', show: showCsvIcon},
      {icon: 'download_pdf', callback: this.configureReport, tooltip: 'Configure & Run Report', show: showPdfIcon},
      {icon: 'report', callback: this.configureReport, tooltip: 'Configure & Run Report', show: showReportIcon}
    ]
    return {
      name: 'Reports',
      reportData: [],
      reportSearch: true,
      fileName: '',
      modalIsActive: {
        reportConfig: false,
        reportResults: false
      },
      dynamicReportColumns: [
        // {name: 'Id', id: 'guid', colId: 'id', link: nameLink, formatter: shortMac},
        {label: '', id: 'outputs', controls: controlIcons},
        {label: 'Name', id: 'name', colId: 'name'},
        {label: 'Description', id: 'description', colId: 'desription'}
      ],
      reportTableOptions: {
        paginate: true,
        perPage: 10,
        searchable: false,
        sortable: true,
        sortIndex: 7,
        sortType: 'desc'
      },
      selectedReport: {}
    }
  },
  methods: {
    configureReport (id) {
      console.log('Select ID: ', id)
      this.selectedReport = id
      this.openModal('reportConfig')
    },
    runReport (reportData) {
      this.reportData = reportData
      this.fileName = reportData.fileName
      this.reportSearch = false
      // Open results modal
      this.modalIsActive.reportResults = true
    }
  },
  mixins: [modalWindowManager]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
