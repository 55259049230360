<template>
  <intelyt-modal
    :isActive="isActive"
    :title="modalTitle"
    :width="1280"
    :copyLinkTxt="copyLinkTxt"
    :uniqueId="uniqueId"
    :modalControls="[{callback: downloadShockData, icon: {name: 'download', label: 'Download Shock Data', show: showDownloadIcon}}]"
    v-on:close="close()"
  >
    <section class="modal-card-body">
      <div class="content">
        <div class="columns">
          <div class="column">
            <intelyt-list :items="eventDetails" numColumns=1></intelyt-list>
            <intelyt-map
              :markers="markers"
              :mapWidth="600"
              :zoom="10"
            ></intelyt-map>
          </div>
          <div class="column">
            <base-chart classname='modal-chart-style' :showLoadingIcon='showLoadingIcon' :options='shockChartOpts'>
            </base-chart>
            <svg-tag :macId="macId" :shkObj="shockEvent" ref="shockSvg" v-on:updateCounter="updateCounter($event)"></svg-tag>
          </div>
        </div>
      </div>
    </section>
    <template slot="footer">
      <a class="button" @click="close()">Close</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'
import SvgTag from '@/components/svgTag'
import {chartOptionFactory} from '@/store/factories'
import {getUrl, getDateFormat, exportAsCSV} from '@/store/helpers'
import { mapActions, mapState } from 'vuex'
import moment from 'moment-timezone'

export default {
  name: 'ShockModal',
  components: {
    IntelytModal,
    SvgTag
  },
  computed: {
    copyLinkTxt () {
      if (!this.alertObj) return ''
      return getUrl(`${this.$route.fullPath}&m=shock&entryId=${this.alertObj.entryId}`)
    },
    uniqueId () {
      if (!this.alertObj) return ''
      // skipcq:JS-W1043 - Skip redundant literal in a logical expression
      return this.alertObj.entryId || ''
    },
    eventDetails () {
      const tmzn = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      // const timezone = moment().tz(tmzn).format('z')
      // const localTime = moment.utc(this.shockEvent.timestamp, 'ddd MMM D HH:mm:ss YYYY').tz(tmzn).format(`${getDateFormat()} HH:mm:ss`)
      const localTime = moment.utc(this.shockEvent.timestamp).tz(tmzn).format(`${getDateFormat()} HH:mm:ss`)
      // const utcTime = moment.utc(this.shockEvent.timestamp, 'ddd MMM D HH:mm:ss YYYY').format(`${getDateFormat()} HH:mm:ss`)
      const utcTime = moment.utc(this.shockEvent.timestamp).format(`${getDateFormat()} HH:mm:ss`)
      // const accelUnits = this.shockEvent.acceleration ? this.shockEvent.acceleration.map(pt => pt.toFixed(2)) : []
      const retData = [
        {label: `Time (${moment.tz(tmzn).zoneAbbr()})`, value: localTime},
        {label: 'Time (UTC)', value: utcTime},
        {label: 'Frequency (hz)', value: `${this.shockEvent.dataRate}`},
        {label: 'Displayed Duration (ms)', value: `${(this.shockEvent.interval * 31).toFixed(1)}`},
        // {label: '&Delta;Vx (m/s)', value: this.shockEvent.dX || ''},
        // {label: '&Delta;Vy (m/s)', value: this.shockEvent.dY || ''},
        // {label: '&Delta;Vz (m/s)', value: this.shockEvent.dZ || ''},
        {label: '&Delta;Vx/&Delta;Vy/&Delta;Vz (m/s)', value: `${this.shockEvent.dX ?? '-'}/${this.shockEvent.dY ?? '-'}/${this.shockEvent.dZ ?? '-'}`},
        {label: 'Max X/Max Y/Max Z (g)', value: `${this.shockEvent.maxX ?? '-'}/${this.shockEvent.maxY ?? '-'}/${this.shockEvent.maxZ ?? '-'}`}
        // {label: '&Delta;Vx/&Delta;Vy/&Delta;Vz (m/s)', value: `${this.shockEvent.dX || '-'}/${this.shockEvent.dY || '-'}/${this.shockEvent.dZ || '-'}`},
        // {label: 'Acceleration (m/s^2)', value: accelUnits || ''}

      ]
      return retData
    },
    markers () {
      if (!this.alertObj) return []
      const position = {lat: parseFloat(this.alertObj.latitude), lng: parseFloat(this.alertObj.longitude)}
      const tmzn = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const localTime = moment.utc(this.shockEvent.timestamp, 'ddd MMM D HH:mm:ss YYYY').tz(tmzn).format(`${getDateFormat()} HH:mm:ss`)
      const infoText = `Time (${moment.tz(tmzn).zoneAbbr()}): ${localTime}</br>Position: ${JSON.stringify(position)}`
      const marker = {
        icon: 'alertIcon',
        infoText,
        position
      }
      return [marker]
    },
    shockChartOpts () {
      // Show the filtered data if (a) it is explicity chosen OR (b) there is no raw
      // data to display
      const interval = this.shockEvent.interval
      // const showFiltered = this.filteredActive === 'T' || !Object.keys(this.shockEvent).includes('X')
      const visibleSeries = [
        {
          name: 'X Axis',
          showSymbol: false,
          type: 'line',
          data: this.shockEvent.X
        },
        {
          name: 'Y Axis',
          showSymbol: false,
          type: 'line',
          data: this.shockEvent.Y
        },
        {
          name: 'Z Axis',
          showSymbol: false,
          type: 'line',
          data: this.shockEvent.Z
        },
        {
          name: 'Aggregate',
          showSymbol: false,
          type: 'line',
          data: this.shockEvent.RMS
        }
      ]
      if (this.counter >= -1) {
        visibleSeries[0].markLine = {
          silent: true,
          lineStyle: {color: '#333', width: 2},
          data: [
            {
              xAxis: this.counter,
              label: {
                formatter (params) {
                  return `${(params.value * interval).toFixed(1)} (ms)`
                }
              }
            }
          ]
        }
      }
      const title = this.chartTitle
      const xAxis = {
        type: 'value',
        boundaryGap: false,
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
      }
      const yAxis = [
        {name: 'Shock (g)'}
      ]
      const legend = {bottom: 10}
      const series = visibleSeries
      // const options = {xAxis}
      const options = {xAxis, yAxis, legend}
      // let opts = chartOptionFactory(title, series, options)
      // console.log('OPTS: ', opts)
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      return chartOptionFactory(chartConfig, title, series, options, {}, tmz)
    },
    showCSVDownload () {
      return Object.values(this.shockEvent.X).length > 0
    },
    chartTitle () {
      return `Shock Event [${this.macId}]`
    },
    ...mapState(['shockEvent'])
  },
  data () {
    return {
      // Emphasis point on chart
      counter: 31,
      modalTitle: 'Shock Event',
      showDownloadIcon: true,
      showLoadingIcon: false,
      vectLen: 80
    }
  },
  emits: ['close'],
  methods: {
    close () {
      this.$refs.shockSvg.resetVars()
      this.$emit('close')
    },
    downloadShockData () {
      // Hide the icon
      this.showDownloadIcon = false
      const axisList = ['X', 'Y', 'Z']
      const points = this.shockEvent.X.length
      let headerList = Object.keys(new Array(points).fill(0))
      headerList = ['Axis', ...headerList]
      const rows = []
      axisList.forEach(axis => {
        const axisVals = this.shockEvent[axis].map(val => val[1])
        rows.push([axis, ...axisVals])
      })
      exportAsCSV(headerList, rows, 'shock-data.csv')
      // Enable the icon after 2 seconds
      setTimeout(() => {this.showDownloadIcon = true}, 2000)
    },
    // shockVector () {
    //   // return indexOf(Math.max(this.shockEvent.RMS));
    //   // return this.shockEvent.RMS.indexOf(Math.max(this.shockEvent.RMS));
    //   // return Math.max(this.shockEvent.RMS)
    //   const vectLen = 60
    //   const radians = 45 * Math.PI / 180
    //   const allRMS = this.shockEvent.RMS.map(x => x[1])
    //   const maxRMS = Math.max(...allRMS)
    //   const maxIndx = allRMS.indexOf(maxRMS)
    //   const sX = this.shockEvent.X[maxIndx][1] * vectLen / maxRMS
    //   const sY = this.shockEvent.Y[maxIndx][1] * vectLen / maxRMS
    //   const sZ = this.shockEvent.Z[maxIndx][1] * vectLen / maxRMS
    //   return [sX, sY, sZ]
    // },
    updateCounter (cnt) {
      this.counter = cnt
    },
    ...mapActions([])
  },
  /*
    isActive - controls modal visibiltiy
    showFiltered - controls whether controls for filtere vs. unfiltered views are enabled.
  */
  props: {
    isActive: Boolean,
    macId: String,
    alertObj: {
      type: [Boolean, Object],
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.modal-chart-style {
  height: 300px;
  float: none;
  clear: both;
}
</style>
