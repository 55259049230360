import {
  createRouteMapProvider,
  getQueryProvider,
  getTableEntriesProvider,
  updateRouteMapProvider
} from '../providers'
// import {round} from '@/store/helpers.js'

import Vue from 'vue'
// import moment from 'moment-timezone'

// initial state
const getDefaultState = () => {
  return {
    all: {},
    active: '*',
    associationsInitialized: false,
    zones: []
  }
}

const state = getDefaultState()

// Prepare the payload for the route or fence for the initial load
const getGeozonePayload = (route) => {
  // let notes = route.notes.replace(',""', '')
  return {
    initialized: false,
    alternateNames: route.alternateNames.split(';').slice(1, -1),
    associationList: route.associationIdList.split(';').slice(1, -1),
    description: route.description,
    mapConfigNameType: route.mapConfigNameType,
    mapConfigTypeId: route.mapConfigTypeId,
    mapId: parseInt(route.mapId),
    name: route.name,
    status: route.enabled === '1' ? 'Enabled' : 'Disabled',
    notes: route.notes,
    coordinates: []
  }
}

// getters
const getters = {}

// actions
const actions = {
/**
 * Gets mapAssociations and mapPoints for user's company and passes the values
 * to {@link setGeofences} for processing and addition to the state <br>
 * Points are pre-processed into object with key of indexNum and coords in
 * object of format coords: {lat: <latitude value>, lng: <longitude value>}<br>
 * @param {object} store
 * @param {object} store.commit  - Vuex store commit object
 * @param {object} store.state  - Vuex store state object
 */
  initializeAll ({ commit, state, rootState }, {force = false, init = true, mapIds = [], routeIds = []} = {}) {
    // Execute the function, when the state have no value or forced to initialize again
    if (!force && Object.values(state.all).length !== 0) {
      return
    }
    // add the module in the initialized modules array in state to reset on logging out
    if (!rootState.initialized.includes('geofences')) {
      commit('setInitializedModule', 'geofences', { root: true })
    }
    let where = 'enabled = 1'
    if (mapIds.length > 0) where += ` AND mapId in (${mapIds.join()})`
    const associationPayload = {
      tableName: 'shippingRouteMapAssociations',
      where: where
    }
    const pointsPayload = {
      tableName: 'shippingRouteMapPoints',
      where: where,
      max: 2500
    }
    if (routeIds.length > 0) {
      routeIds.forEach((id) => {
        commit('INITIALIZE_OBJECT', id)
      })
    }
    Promise.all([
      getTableEntriesProvider(rootState, associationPayload),
      getTableEntriesProvider(rootState, pointsPayload)
    ]).then(responses => {
      // Build object w/ keys of indexNum for points
      const transformPoints = (acc, pt) => {
        acc[`${pt.indexNum}-${pt.mapId}`] = {
          lat: Number(pt.latitude),
          lng: Number(pt.longitude)
        }
        return acc
      }
      let assoc = {}
      for (let val of Object.values(responses[0].data)) {
        assoc[val.mapId] = val
      }
      const points = Object.values(responses[1].data).reduce(transformPoints, {}) || []
      commit('SET_GEOFENCES', {associations: assoc, points: points, init: init})
    }).catch(e => {
      console.error('initializeAll geofence ERROR', e)
    })
  },
  /**
 * Initialize the Routes list alone with limited values to show in the routes list
 * initializeRouteList - This method is used retrive the routes list
 * only with the required and limited values. The fields are retrieved from the
 * table 'shippingRouteMapAssociations' using the getTableEntriesProvider.
 * @param {object} store
 * @param {object} store.commit  - Vuex store commit object
 * @param {object} store.state  - Vuex store state object
 */
  initializeRouteList ({ commit, dispatch, state, rootState }, {force = false} = {}) {
    // Execute the function, when the state have no value or forced to initialize again
    if (!force && Object.values(state.all).length !== 0) {
      return
    }
    // add the module in the initialized modules array in state to reset on logging out
    if (!rootState.initialized.includes('geofences')) {
      commit('setInitializedModule', 'geofences', { root: true })
    }
    const associationPayload = {
      tableName: 'shippingRouteMapAssociations',
      where: `enabled = 1 AND mapConfigNameType = 'Route'`,
      fields: ['mapId']
    }
    getTableEntriesProvider(rootState, associationPayload).then(response => {
      const mapIds = Object.values(response.data).map(m => m.mapId)
      dispatch('initializeAll', {force: true, init: false, mapIds: mapIds})
    }).catch(e => {
      console.error('initializeRouteList ERROR', e)
    })
  },
  /**
 * Initialize the Associations list alone with the required and limited values to show in the
 * Associations list tabs by its type in the edit geozone modal. The fields are retrieved from the
 * table 'shippingRouteMapAssociations' using the getTableEntriesProvider. This will load the associations
 * which is not available in state.
 * @param {object} store
 * @param {object} store.commit  - Vuex store commit object
 * @param {object} store.state  - Vuex store state object
 */
  initializeAssocisationsList ({ commit, state, rootState }) {
    if (state.associationsInitialized) {
      return
    }
    // Get the associations list, which is available in the state
    const associations = Object.values(state.all).filter(assoc => assoc.initialized && assoc.mapConfigNameType === 'Geozone').map(zone => zone.mapId)
    let where = `enabled = 1 AND mapConfigNameType = 'Geozone'`
    if (associations.length > 0) {
      // Skip the associations which are already available in the state
      where += ` AND mapId NOT IN (${associations.join()})`
    }
    const associationPayload = {
      tableName: 'shippingRouteMapAssociations',
      where: where,
      fields: ['name', 'description', 'notes', 'mapId', 'associationIdList', 'enabled', 'alternateNames', 'mapConfigNameType', 'mapConfigTypeId']
    }
    getTableEntriesProvider(rootState, associationPayload).then(response => {
      const routes = Object.values(response.data)
      if (routes.length === 0) {
        return
      }
      for (let route of routes) {
        const routePayload = getGeozonePayload(route)
        commit('APPLY_GEOFENCE', routePayload)
      }
      commit('INITIALIZE_ASSOCIATIONS')
    }).catch(e => {
      console.error('initializeAssocisationsList ERROR', e)
    })
  },
  /**
 * Initialize a sungle route by name.  Lookup name in routeAssociation table (alertnateNames column)
 * and use the mapId to initialize the route and it's childred.
 * @param {string} routeName
 */
  initializeSingleRoute ({ commit, dispatch, state, rootState }, {routeName = '', guid = false} = {}) {
    if (routeName && routeName.length === 0) return false
    /* get route id from table */
    return new Promise((resolve, reject) => {
      const payload = {
        tableName: 'shippingRouteMapAssociations',
        where: `( name = "${routeName}" OR alternateNames LIKE "%${routeName}%" )`,
        fields: ['mapId']
      }
      getTableEntriesProvider(rootState, payload).then(response => {
        // If route is not initialized, call getRoute details to update.  Otherwise just resolve.
        if (Object.keys(response.data).length === 1 && state.all[response.data[0].mapId]) {
          const routeId = parseInt(response.data[0].mapId)
          // If the route is not initialized, then initialize it
          if (!state.all[response.data[0].mapId].initialized) dispatch('getRouteDetails', {mapId: routeId})
          resolve(parseInt(routeId))
          if (guid) {
            commit('shipments/UPDATE_SHIPMENT', {id: guid, data: {routeId: routeId}}, {root: true})
          }
        }
        resolve()
      }).catch(e => {
        console.error('Route search error', e)
        reject(e)
      })
    })
  },
  /**
 * Get the Route and associtions details which are linked with the route, when
 * the user access / load the particular route. The details are loaded from the table
 * using the getQueryProvider using the query 'getRouteDetails'. This will return all the
 * fields required for the routes page and the points for the associations linked with the particular route.
 * If the route is already initialized, the function returns early.
 * @param {object} store
 * @param {object} store.commit  - Vuex store commit object
 * @param {object} store.state  - Vuex store state object
 */
  getRouteDetails ({ commit, dispatch, state, rootState }, {mapId = 0} = {}) {
    // Don't execute if there is no map id or already initialized
    if (mapId === 0 || state.all[mapId].initialized) {
      return
    }
    return new Promise((resolve, reject) => {
      const filterFn = z => { return !state.all[z] || !state.all[z].initialized }
      /**
       * getAssociations - This method is used to get the nested associations for the given route
       * It's a recursive method to iterate the each associations and it's nested associations until
       * it founds no associations
       */
      const getAssociations = (id) => {
        const zone = state.all[id]
        const associations = zone ? [...state.all[id].associationList] : false
        if (associations) {
          for (const assoc of associations) {
            const getAssocs = getAssociations(assoc)
            if (getAssocs) associations.push(...getAssocs)
          }
          return associations
        }
        return false
      }
      let mapIds = getAssociations(mapId)
      mapIds = (mapIds && mapIds.length > 0) ? mapIds.filter(filterFn) : []
      mapIds.push(mapId)
      const payload = {
        query: 'getRouteDetails',
        params: [mapIds.join(',')]
      }
      getQueryProvider(rootState, payload).then(response => {
        const routes = Object.values(response.data)
        if (routes.length === 0) {
          return
        }
        let assoc = {}
        for (let val of routes) {
          assoc[val.mapId] = val
        }
        const missingAssoc = []
        for (let route of routes) {
          let coordinates = route.locations.trim() ? route.locations.trim().split(',') : []
          if (coordinates.length > 0 && coordinates[0].length > 1) {
            coordinates = coordinates.map(p => {
              const point = p.split(':')
              return {lat: Number(point[0]), lng: Number(point[1])}
            })
          }
          const associationMap = p => {
            const zone = assoc[p] || state.all[p]
            if (!zone) { // The association is not found in the response as well as in the state
              missingAssoc.push(p)
              return
            }
            return {name: zone.name, id: parseInt(zone.mapId)}
          }
          let associations = route.associationIdList.split(';').slice(1, -1)
          const routePayload = {
            associations: associations.length > 0 ? associations.map(associationMap) : [],
            associationList: associations,
            mapConfigNameType: route.mapConfigNameType,
            description: route.description,
            mapConfigTypeId: route.mapConfigTypeId,
            mapId: parseInt(route.mapId),
            name: route.name,
            center: coordinates.length > 0 ? coordinates[0] : [],
            coordinates: coordinates,
            status: route.enabled === '1' ? 'Enabled' : 'Disabled',
            pointCount: coordinates.length,
            enabled: route.enabled,
            alternateNames: route.alternateNames.split(';').slice(1, -1),
            notes: route.notes,
            initialized: true
          }
          commit('APPLY_GEOFENCE', routePayload)
        }
        // If missing association is found in the response, initialize them
        if (missingAssoc.length > 0) dispatch('initializeAll', {force: true, init: true, mapIds: missingAssoc})
        resolve([])
      }).catch(e => {
        console.error('Route details error', e)
        reject(e)
      })
    })
  },
  /**
   * Adds or updates a single RouteMap object.<br>
   * The method calls updateRouteMapProvider if the object already exists and
   * uses createGeofenceMapProvider if the object is new.
   * Note: Main Map Object is shippingRouteMapAssociations in database and points
   * are stored in shippingRouteMapPoints table<br>
   * Runs {@link tbd} to perform updates.
   * @param {object} state - Vuex state
   * @param {boolean} isnew - Indicates whether object is new (create) or already
   * exists (update)
   * @param {object} payload - TBD
   * @param {object} payload.mapName - CUrrent Name of Map Object
   * @param {object} payload.companyName - TBD
   * @param {object} payload.map - TBD
   * @param {object} payload.map.points - List of points for geozone with each point
   * having the pattern {longitude: ##.####, latitude: ###.####}
   * @param {object} payload.map.tbd - TBD
   * @returns {Promise} Promise object represents the return value
   */
  editRouteMap ({ commit, rootState }, [isNew, payload, statePayload]) {
    // createRouteMapProvider(state, updatePayload)
    return new Promise((resolve, reject) => {
      if (isNew) {
        createRouteMapProvider(rootState, payload).then(response => {
          statePayload.mapId = response.data.mapId
          statePayload.pointIdList = response.data.mapPointIds.join(';')
          commit('APPLY_GEOFENCE', statePayload)
          let msg = {message: 'Successfully created: ' + payload.mapName, mapId: response.data.mapId}
          resolve(msg)
        }).catch(e => {
          // let msg = {message: e.response.data.createMapSet[0], mapId: payload.mapId}
          let msg = {message: e.response.data.errors[0].detail, mapId: payload.mapId}
          reject(msg)
        })
      } else {
        // Geofence is not new, so update object
        updateRouteMapProvider(rootState, payload).then(() => {
          commit('APPLY_GEOFENCE', statePayload)
          let msg = {message: 'Successfully updated: ' + payload.mapName, mapId: payload.mapId}
          resolve(msg)
        }).catch(e => {
          let msg = {message: e.response.data.errors[0].detail, mapId: payload.mapId}
          reject(msg)
        })
      }
    })
  },
  getZoneList ({ rootState, state, commit }) {
    // If zones already loaded, then do not proceed
    // if (state.zones.length > 0) return
    // Filter the routes which has mapConfigTypeId === 1 and mapConfigNameType === 'Route' and have associations
    const filterZoneRoutes = Object.values(state.all).filter(r => r.mapConfigTypeId === '1' && r.mapConfigNameType === 'Route' && r.associationList.length > 0)
    let assocList = []
    // Prepare the association list and get the zone list using it's mapId
    filterZoneRoutes.forEach(r => {
      assocList = assocList.concat(r.associationList)
    })
    if (assocList.length === 0) return
    const whereParams = `mapId IN (${[...assocList]})`
    const payload = {
      tableName: 'shippingRouteMapAssociations',
      where: whereParams,
      sort: 'entryId DESC'
    }
    getTableEntriesProvider(rootState, payload)
      .then(response => {
        if (Object.values(response.data).length === 0) return
        const zones = Object.values(response.data).map(z => z.name)
        commit('SET_ZONES', zones)
      })
  }
}

// mutations
const mutations = {
  /**
   * Adds a geofence to state
   * @param {object} state - Vuex state
   * @param {object} data - data payload
   */
  ADD_GEOFENCE (state, data) {
    // Vue.set(state.geofences[data.mapName], 'description', data.map.description)
    const coords = data.map.points.map(pt => { return {lat: pt.latitude, lng: pt.longitude} }) || []
    const associationObj = data.map.subMapID.map(pt => { return {id: pt, name: 'test'} }) || []
    state.all[data.mapId] = {
      name: data.mapName,
      description: data.map.description,
      alternateNames: data.map.altNames,
      associations: associationObj,
      coordinates: coords
    }
  },
  /**
   * Sets the selected geofence to the provided value
   * @param {object} selectedValue - Value to set state to
   */
  RESET_ACTIVE (state, fence = '*') {
    state.active = fence
  },
  /**
   * Resets object state to initial values set upon creation.
   */
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  /**
   * Sets geofence information for in state using response passed by
   * to {@link initializeGeofences} for map points and associations<br>
   * @param {Object} state  - Vuex store state object
   * @param {Array} payload  - Response payload from {@link
   * getTableEntriesGenericProvider for locations table}
   * @param {Array} payload.associations  - Response payload from {@link
   * getTableEntriesGenericProvider for shippingRouteMapAssociations table}
   * @param {Array} payload.points  - Response payload from {@link
   * getTableEntriesGenericProvider for shippingRouteMapPoints table}
   * @todo Calculate center or geofence for use instead of using first point.
   */
  SET_GEOFENCES (state, {associations = {}, points = {}, init = true} = {}) {
    for (let assoc of Object.values(associations)) {
      const associationAlreadyDefined = assoc.mapId in state.all
      if (!associationAlreadyDefined) {
        // Get points from idList, removing first and last due to delimiters
        // on both ends of source string
        const pointIdList = assoc.pointIdList.split(';').slice(1, -1)
        const pointList = pointIdList.map(pt => points[`${pt}-${assoc.mapId}`])
        // if has associations -> group otherwise fence
        const fenceType = assoc.associationIdList.length > 0 ? 'group' : 'fence'
        const assocs = assoc.associationIdList.split(';').slice(1, -1)
        const associationMap = p => {
          if (!associations[p]) return {}
          return {name: associations[p].name, id: parseInt(associations[p].mapId)}
        }
        const fence = {
          center: pointList[0],
          coordinates: pointList,
          createDate: assoc.createDate,
          description: assoc.description,
          entryId: assoc.entryId,
          enabled: assoc.enabled,
          associations: assocs.map(associationMap),
          associationList: assocs,
          alternateNames: assoc.alternateNames.split(';').slice(1, -1),
          initialized: init,
          lastUpdate: assoc.lastUpdate,
          mapId: parseInt(assoc.mapId),
          mapConfigNameType: assoc.mapConfigNameType,
          mapConfigTypeId: assoc.mapConfigTypeId,
          notes: assoc.notes,
          pointIdList: assoc.pointIdList,
          name: assoc.name,
          class: fenceType,
          status: assoc.enabled === '1' ? 'Enabled' : 'Disabled',
          pointCount: pointList.length
        }
        Vue.set(state.all, assoc.mapId, fence)
      }
    }
  },
  /**
   * Add or edits existing geofence information in state for a single element
   * @param {object} state - Vuex state
   * @param {object} updatePayload - data payload
   */
  APPLY_GEOFENCE (state, updatePayload) {
    Vue.set(state.all, updatePayload.mapId, updatePayload)
  },
  /**
   * Add or edits existing geofence information in state for a single element
   * @param {object} state - Vuex state
   * Will be called to update the 'associationsInitialized' value in the state
   */
  INITIALIZE_ASSOCIATIONS (state) {
    Vue.set(state, 'associationsInitialized', true)
  },
  /**
   * Marks a geozone/route or other object as initilaized.
   * @param {object} state - Vuex state
   */
  INITIALIZE_OBJECT (state, mapId) {
    Vue.set(state.all[mapId], 'initialized', true)
  },
  /**
   * Marks a geozone/route or other object as initilaized.
   * @param {object} state - Vuex state
   */
  SET_ZONES (state, zones) {
    Vue.set(state, 'zones', zones)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
