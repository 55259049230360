<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      :width="width"
      v-on:close="close()"
    >
      <div class="content" v-show="!submitted">
          <div class="field" :key="index" v-for="(element, index) in formFields">
            <text-box v-if="element.type === 'text'" :label="element.label" :mode="mode" :formRules="element.rules" :disabled="element.disabled || false" :default="element.defaultVal || ''" :field="element.field" :idx="index" :tooltip="element.tooltip || ''" :reset="active"/>
            <radio-element v-if="element.type === 'radio'" :label="element.label" :mode="mode" :default="element.defaultVal" :field="element.field" :formRules="element.rules || {}" :options="element.options" :idx="index" :tooltip="element.tooltip || ''" :reset="active"/>
            <check-box v-if="element.type === 'checkbox'" :field="element.field" :label="element.label" :mode="mode" :default="element.defaultVal || ''" :formRules="element.rules || {}" :idx="index" :tooltip="element.tooltip || ''" :reset="active"/>
            <chime-list v-if="element.type === 'chimeList'" :label="element.label" :mode="mode" :default="element.defaultVal || ''" :idx="index" :tooltip="element.tooltip || ''" :reset="active"/>
            <device-select v-if="element.type === 'deviceSelect'" :searchable="element.searchable || false" :label="element.label" :mode="mode" :default="element.defaultVal || ''" :formRules="element.rules || {}" :devType="element.devType || 'tags'" :tooltip="element.tooltip || ''" :reset="active"/>
            <device-search v-if="element.type === 'deviceSearch'" :label="element.label" :mode="mode" :default="element.defaultVal || ''" :devType="element.devType || '1'" :tooltip="element.tooltip || ''" :reset="active"/>
            <hidden-field v-if="element.type === 'hidden'" :mode="mode" :default="element.defaultVal || ''" :field="element.field" :reset="active"/>
            <location-select v-if="element.type === 'locationSelect'" :field="element.field" :mode="mode" :default="element.defaultVal || ''" :label="element.label" :filter="element.filter" :tooltip="element.tooltip || ''" :reset="active"/>
            <location-enter v-if="element.type === 'locationEnter'" :field="element.field" :mode="mode" :label="element.label" :tooltip="element.tooltip || ''" :idx="index" :reset="active"/>
            <simple-select v-if="element.type === 'simpleSelect'" :searchable="element.searchable || false" :field="element.field" :mode="mode" :default="element.defaultVal || ''" :label="element.label" :tooltip="element.tooltip || ''" :fieldConfig="element" :idx="index" :reset="active" :formRules="element.rules || {}" :defaults="defaults" :submitted="submitted" />
            <bill-of-lading v-if="element.type === 'billOfLading'" :label="element.label" :mode="mode" :default="element.defaultVal || {}" :tooltip="element.tooltip || ''" :source="element.source" :idx="index" :reset="active"/>
          </div>
      </div>
      <div class="content" v-if="submitted">
        {{message}}
      </div>
      <!-- <div>invalid: {{invalid}} - Validator {{formComplete}}</div> -->
      <template slot="footer">
        <span v-if="!submitted">
          <button class="button is-primary" :disabled="invalid" v-if="mode === 'edit'" @click="edit()">Update</button>
          <button class="button is-primary" :disabled="!formComplete || invalid" v-if="mode !== 'edit'" @click="create()">Submit</button>
          <a class="button" @click="close()">Cancel</a>
        </span>
        <span v-if="submitted">
          <a class="button" @click="close()">Close</a>
        </span>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'
import TextBox from '@/components/form/TextBox.vue'
import RadioElement from '@/components/form/RadioElement.vue'
import CheckBox from '@/components/form/CheckBox.vue'
import ChimeList from '@/components/form/ChimeList.vue'
import DeviceSearch from '@/components/form/DeviceSearch.vue'
import DeviceSelect from '@/components/form/DeviceSelect.vue'
import HiddenField from '@/components/form/HiddenField.vue'
import LocationSelect from '@/components/form/LocationSelect.vue'
import SimpleSelect from '@/components/form/SimpleSelect.vue'
import LocationEnter from '@/components/form/LocationEnter.vue'
import BillOfLading from '@/components/form/BillOfLading.vue'
import { stringTemplateParser } from '../store/helpers'
import { mapActions } from 'vuex'

export default {
  name: 'AddEditModal',
  components: {
    IntelytModal,
    TextBox,
    CheckBox,
    ChimeList,
    DeviceSearch,
    DeviceSelect,
    HiddenField,
    LocationEnter,
    LocationSelect,
    SimpleSelect,
    BillOfLading,
    RadioElement
  },
  computed: {
    // skipcq - JS-0044: Allow Cyclomatic complexity more than allowed
    formComplete () {
      /*
        Return true when all required fields have been populated properly
        The fields that are needed are passed in the property 'required'
        These initially set the valid value for any included to false and then
        the logic of the function sets to true as the fields are completed.
      */
      const formValues = this.$store.state.form.new
      let validScript = !this.required.includes('script')
      let validDevice = !this.required.includes('device')
      let validShipmentId = !this.required.includes('shipmentId')
      let validOrigin = !this.required.includes('origin')
      let validDestination = !this.required.includes('destination')
      // configScript field validation
      if (formValues.configScript && formValues.configScript.length > 0 && formValues.configScript.slice(-4) === '.cfg') validScript = true
      // Device field validation
      if (formValues.device && formValues.device.length > 0) validDevice = true
      // shipmentId field validation
      if (formValues.shipmentId && formValues.shipmentId.length > 0) validShipmentId = true
      // origin field validation
      // skipcq: JS-W1044.  Allow Logical operator can be refactored to optional chain
      if (formValues.origin && formValues.origin.name && formValues.origin.latitude && formValues.origin.longitude && formValues.origin.name.length > 0 && formValues.origin.latitude * formValues.origin.longitude !== 0.0) validOrigin = true
      // destination field validation
      // skipcq: JS-W1044.  Allow Logical operator can be refactored to optional chain
      if (formValues.destination && formValues.destination.name && formValues.destination.latitude && formValues.destination.longitude && formValues.destination.name.length > 0 && formValues.destination.latitude * formValues.destination.longitude !== 0.0) validDestination = true
      // Return true, when all the conditions true. Else return false
      // console.debug('Valid: ', validScript, validDevice , validShipmentId , validOrigin, validDestination)
      return validScript && validDevice && validShipmentId && validOrigin && validDestination
    }
  },
  data () {
    return {
      deviceType: false,
      entryId: -1,
      guid: '',
      message: '',
      submitted: false,
      active: false,
      formFields: []
    }
  },
  emits: ['close'],
  methods: {
    // formFields computed property will take input form fields and loop through each item
    // and assign validation rules and default value to the fields and return to form
    setFormFields () {
      this.formFields = []
      // if (this.formElements.length === 0) return []
      const rules = {
        shipmentId: {required: true, max: 60, regex: /^(\w|\s|\(|\)|-)+$/},
        notes: { max: 75, regex: /^(\w|\s|-|\.,*)+$/ },
        notes225: { max: 225, regex: /^(\w|\s|-|\*)+$/ },
        customer: { max: 50, regex: /^(\w|\s|-)+$/ },
        location: { max: 50, regex: /^(\w|\s|-|\.)+$/ },
        latitude: { min_value: -90, max_value: 90 },
        longitude: { min_value: -180, max_value: 180 },
        integer: {regex: /^(\d+)+$/},
        required: {required: true},
        device: {min: 11, max: 11, required: true},
        none: {required: false}
      }
      const vm = this
      const elements = this.formElements || []
      this.formFields = elements.map(cfg => {
        const config = {...cfg}
        // Get the tooltip obj from props
        const tooltips = vm.tooltipConfig || {}
        // console.debug('tooltips', tooltips)
        // dynamic rule implementation based on validationRule key value from configuration
        if (config.validationRule) config.rules = rules[cfg.validationRule]
        // Default value implementation
        let value = ''
        // If the value is found in the shipment object, use that value as the default,
        // if not check to see if static default is assigned in the configuration and use it
        if (config.field && this.defaults && Object.keys(this.defaults).length > 0) {
          let defaultValue = ''
          // Split the field attribute using the . character.
          const cfgFldList = Array.isArray(config.field) ? config.field : config.field.split('.')
          // If no . found, then apply the default value for the field
          if (cfgFldList.length === 1) defaultValue = this.defaults[config.field]
          else if (cfgFldList.length > 1) { // If the . found(more than one item), then loop through the default object and apply the value
            defaultValue = this.defaults
            cfgFldList.forEach(fld => {
              if (defaultValue) defaultValue = defaultValue[fld]
            })
          }
          value = defaultValue
        // } else if (config.hasOwnProperty('default')) {
        } else if (Object.prototype.hasOwnProperty.call(config, 'default')) {
          value = config.default
        }
        // Get the field name for the tooltip
        let fieldName = config.field
        // If there is no field name and devType value is present in config
        // Then it's a device field. Use the deviceType as the field name
        // and get the tooltip value from config
        if (!config.field && config.devType) fieldName = config.devType
        let tooltipTxt = ''
        // If the field is string and got the tooltip value in config object, then use it
        if (typeof fieldName === 'string' && tooltips[fieldName]) tooltipTxt = tooltips[fieldName]
        // If it is a list of fields, then loop and send the object with field name
        // as prop and tooltip string as value
        if (Array.isArray(fieldName)) {
          tooltipTxt = {}
          fieldName.forEach(fld => {
            tooltipTxt[fld] = tooltips[fld]
          })
        }
        config.tooltip = tooltipTxt
        value = typeof value === 'boolean' ? value.toString() : value
        if (value) config.defaultVal = value
        return config
      })
    },
    close () {
      // reset mutator to dynamically reset the form new/edit obj in state based on the mode value
      const resetMutator = this.mode === 'edit' ? 'RESET_EDIT_FORM_OBJ' : 'RESET_FORM_OBJ'
      // Reset the state new form object, when the modal is closed
      this.$store.commit(resetMutator)
      /* Clear message and set submitted back to false to prepare for next use */
      this.message = ''
      this.deviceType = false
      this.submitted = false
      /* Close modal */
      this.$emit('close')
    },
    create () {
      const formValues = this.mode === 'add' ? {...this.$store.state.form.new} : {...this.$store.state.form.edits}
      if (this.deviceType) formValues.deviceType = this.deviceType
      /* this['shipments/createShipment'](formValues).then(response => {
        // Process the callbacks once the shipment is created
        this.processCallbackActions(formValues)
        const device = this.$store.state.form.new.device
        // Remove the device mac id from the available list
        if (device) this.$store.dispatch('devices/updateAvailableList', {deviceId: device, deviceType: this.deviceType, action: 'remove'})
        this.message = response.message
        this.submitted = true
      }).catch(e => {
        // Set default error message if empty payload, otherwise use e
        this.message = typeof e.response.data[0] !== 'undefined' ? e : 'An error was encontered'
        this.submitted = true
      }) */
      if (!this.callback) return
      const vm = this
      /**
       * Call the command function and print the response message
       */
      const executeCallback = async function () {
        await vm.callback(formValues)
          .then((response) => {
            vm.processCallbackActions(formValues)
            vm.message = response.message
            vm.submitted = true
          })
          .catch(e => {
            // Set default error message if empty payload, otherwise use e
            vm.message = typeof e.response.data[0] !== 'undefined' ? e : 'An error was encontered'
            vm.submitted = true
          })
      }
      executeCallback()
    },
    edit () {
      this.create()
      /* const shpValues = this.$store.state.form.edits
      // Create mapping to convert form names to those required by API
      const fieldMap = {
        customer: 'customData2',
        notes: 'customData3',
        entryIdBOL: 'entryIdBOL',
        clientShipmentId: 'customData',
        customInt: 'stateInt1'
      }
      let editPayload = {
        guid: this.guid,
        entryId: parseInt(this.entryId)
      }
      // Loop through the fields passed and add to payload based on map
      for (const itm of this.formElements) {
        if (itm.field) {
          if (Array.isArray(itm.field)) {
            for (const fld of itm.field) {
              editPayload[fieldMap[fld]] = shpValues[fld]
            }
          } else {
            editPayload[fieldMap[itm.field]] = shpValues[itm.field]
          }
        }
      }
      // if (shpValues.hasOwnProperty('props') && Object.keys(shpValues.props).length > 0) {
      if (Object.prototype.hasOwnProperty.call(shpValues, 'props') && Object.keys(shpValues.props).length > 0) {
        const shipmentProps = this.$store.state.shipments.all[this.guid].props && typeof this.$store.state.shipments.all[this.guid].props === 'object' ? {...this.$store.state.shipments.all[this.guid].props} : {}
        const props = Object.assign(shipmentProps, shpValues.props)
        editPayload.customText = JSON.stringify(props)
        editPayload.props = props
      }
      if (shpValues.billOfLadingType && shpValues.billOfLadingValue) {
        editPayload.itemId = shpValues.billOfLadingType
        editPayload.itemText = shpValues.billOfLadingValue
        editPayload.entryIdBOL = shpValues.entryIdBOL
        editPayload.itemType = 100
      }
      // Submit update and display results
      this['shipments/editShipment'](editPayload).then(response => {
        // Process the callback once the shipment is updated
        this.processCallbackActions(shpValues)
        this.message = response
      }).catch(e => {
        console.log('ERROR: ', e)
        this.message = e
      }).finally(() => {
        this.submitted = true
        this.$emit('refreshShipment')
      }) */
    },
    /**
     * Process the callbacks configured in the field configuration
     */
    processCallbackActions (formValues) {
      const filterFn = function (fld) {
        // Filter when the field have callback
        // When the field have some value
        // When the field value is changed(field value is != default value)
        return fld.callback && (formValues[fld.field] && formValues[fld.field] !== fld.defaultVal)
      }
      const callbackFields = [...this.formFields].filter(filterFn)
      const valueObject = this.mode === 'add' ? formValues : this.defaults
      callbackFields.forEach((fieldConfig) => {
        // Set the field value in the value object
        const fieldValue = formValues[fieldConfig.field]
        // Get the callback params from the config
        const callback = {...fieldConfig.callback}
        // Convert the payload to string to replace the params from value object
        let payload = JSON.stringify(callback.payload)

        const valueObj = {...valueObject, fieldValue}
        // Replace the tokens in the payload with shipment and field values
        payload = stringTemplateParser(payload, valueObj)
        // convert it back to object
        payload = JSON.parse(payload)
        this.$store.dispatch(callback.action, payload)
      })
    },
    ...mapActions([
      'shipments/createShipment',
      'shipments/editShipment'
    ])
  },
  props: {
    title: {
      type: String,
      default: 'Create Shipment'
    },
    callback: {
      type: [Function, Boolean],
      default: false
    },
    defaults: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      default: false
    },
    formElements: Array,
    mode: {
      type: String,
      default: 'add'
    },
    required: {
      type: Array,
      default: () => ['script', 'device', 'shipmentId', 'origin', 'destination']
    },
    width: {
      type: Number,
      default: 600
    },
    tooltipConfig: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    // whenever modal opens in edit mode, set guid of shipment - it is required for updates
    isActive: {
      handler (val) {
        this.active = false
        if (val) {
          this.setFormFields()
          this.active = false
          this.$refs.observer.reset()
          // Get the form elements to load the device list
          const frmElmts = Object.values({...this.formElements})
          // Get the device type from the configuration
          const devElmt = frmElmts.filter(fld => fld.type === 'deviceSelect')[0]
          // If the form has the device select component, then load devices
          if (devElmt) {
            // If the device type is configured, then use it
            // Else load tags as default
            // skipcq:JS-W1043 - Skip redundant literal in a logical expression
            const deviceType = devElmt.devType || 'tags'
            this.$store.dispatch('devices/loadAvailableList', { deviceType })
            this.deviceType = deviceType
          }
          const vm = this
          const setTime = function () {
            vm.active = true
          }
          // To set the dfault values, need to set the active state after some delay
          setTimeout(setTime, 300)
          /* if (this.mode === 'edit') {
            this.guid = this.$route.query.id
            this.entryId = this.$store.state.shipments.all[this.guid].entryIdSPL
          } */
        }
      },
      deep: true
    }
  }
}
</script>
