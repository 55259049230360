/** @module store/actions */

import {
  createAndRunActivityTemplateProvider,
  getCompaniesProvider,
  getDeviceIdsProvider,
  getMessagesProvider,
  getQueryProvider,
  getStaticDataProvider,
  getTableEntriesGenericProvider,
  getUsernameProvider,
  getUsersProvider,
  getWarVersionProvider,
  jsReportProvider,
  loadCommandFileProvider,
  loginProvider,
  resetPasswordProvider,
  sendCommandsProvider,
  sendEmailProvider,
  updateUserProvider
} from './providers'

import moment from 'moment-timezone'
import Vue from 'vue'

import config from '../../static/config.json'
import messages from '../../static/messages.json'
// skipcq:JS-E1008 - Skip cyclic imports
import router from '../router'

const BASE_CONFIG = JSON.stringify(config)

export const fetchGenericShipmentDetails = ({ commit, dispatch, state }, {query, markers}) => {
  /*
    find match for guid and username:
      - guid must match pattern of chars separated by hyphen with
        the following counts:  8-4-4-4-12
      - username is set of chars (no spaces) after last hyphen
    if either regex does not match return errror page
  */
  const guidRegexExec = /^(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/.exec(query)
  const usernameRegexExec = /-\w{12}-(\w+)$/.exec(query)
  if (!guidRegexExec || !usernameRegexExec) {
    router.push('/error')
    return
  }

  const guid = guidRegexExec[1]
  const username = usernameRegexExec[1]
  // populate empty object to remove errors in initial page load
  const shipmentPayload = {
    id: guid,
    data: {}
  }
  commit('setPublicShipment', shipmentPayload)
  // build querys for:
  //  - shippingPickList to fetch general details
  //  - shippingStatus to fetch status details
  //  - shippingEventLog to fetch notifications and locations
  const queryPayload = (table, sort = 'entryId DESC') => {
    const payloadObj = {
      guid,
      username,
      tableName: table,
      where: ` guid = "${guid}" AND COALESCE(enabled, 0) < 2 `,
      fields: ['createDate', 'guid', 'origin', 'dest', 'macId'],
      sort
    }
    return payloadObj
  }
  const pickListPayload = queryPayload('shippingPickList')
  const eventLogPayload = queryPayload('shippingEventLog', 'sourceTime ASC')
  const statusPayload = queryPayload('shippingStatus')
  const alertLogPayload = queryPayload('shippingAlertLog')
  // skipcq: JS-0045-Skip Either all code paths should have explicit returns, or none of them
  return new Promise((resolve, reject) => {
    Promise.all([
      getTableEntriesGenericProvider(state, pickListPayload),
      getTableEntriesGenericProvider(state, statusPayload),
      getTableEntriesGenericProvider(state, eventLogPayload),
      getTableEntriesGenericProvider(state, alertLogPayload)
    ]).then(responses => {
      const pickList = responses[0].data[0]
      const status = responses[1].data[0]
      const eventLog = responses[2]
      const alertLog = responses[3]
      // Stored user data in state
      const userLog = {
        companyName: '',
        emailAddress: '',
        fullName: '',
        groups: [],
        groupList: [],
        loginName: '',
        notifyList: '',
        phoneNumber: '',
        propertyMap: '',
        roles: '',
        smsCarrier: '',
        timezone: moment.tz.guess(),
        userClass: '',
        userId: ''
      }
      commit('setUser', userLog)
      // process shippingPickList entry to set basic status
      const pubShipmentPayload = {
        id: pickList.guid,
        data: pickList
      }
      // commit('shipments/UPDATE_SHIPMENT', shipmentPayload)
      commit('setPublicShipment', pubShipmentPayload)
      // sprocess shippingSatatus entry to further augment status
      const departDate = status.departDate ? moment.utc(status.departDate, 'ddd MMM D HH:mm:ss YYYY') : ''
      const statusUpdatePayload = {
        id: guid,
        data: {
          // dates
          departDate,
          lastStatusUpdate: moment.utc(status.lastUpdate, 'ddd MMM D HH:mm:ss YYYY'),
          // location
          latitude: status.latitude,
          longitude: status.longitude,
          // states
          alertState: status.alertState,
          commissionState: status.commissionState,
          onTimeState: status.onTimeState,
          shippingState: status.shippingState
        }
      }
      commit('updatePublicShipmentState', statusUpdatePayload)
      // manage shippingEventLog entries
      if (eventLog !== '') {
        dispatch('shipments/processShipmentEvents', {guid, response: eventLog, markers})
      }
      // console.log('alertLog.data', alertLog.data)
      for (const alertKey in Object.values(alertLog.data)) {
        if (Object.prototype.hasOwnProperty.call(alertLog.data, alertKey)) {
          commit('alerts/SET_ALERT', alertLog.data[alertKey])
        }
      }
      resolve()
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * Retrieves and stores shock data from shock data messages.  There are 3
 * mesages, one for each axis.<br>
 * - If successful, updates state using {@link updateShockEvent}<br>
 * - If error, log to console & return w/o state update
 * Current implementation adds 3 seconds to source time and takes 3 entries just prior to
 * this time.  This accounts for the source time rounding off a seconed or two.
 * @param {object} state - Vuex state
 * @param {object} eventArgs - MAC address of device to query
 * @todo Fix timesamp conrrection to get the data w/o having hadk
 */
export const fetchShockData = ({ commit, state }, eventArgs) => {
  const shockData = {acceleration: eventArgs.acceleration}
  /* Build payload - add source count if it is defined for shipment, otherwise use old values */
  const dt = moment(eventArgs.timestamp + 3000).format('x') / 1000
  const dtLow = dt - 6
  let query = ` UNIX_TIMESTAMP(CONVERT_TZ(sourceTime, '+00:00', @@global.time_zone)) BETWEEN ${dtLow} AND ${dt} `
  if (eventArgs.counter) query += ` AND CONV(SUBSTRING(data, 27, 2), 16, 10) = '${eventArgs.counter}'`
  const requestPayload831 = {
    deviceId: eventArgs.macId,
    pktType: 19,
    msgType: 1,
    max: 6,
    // where: ` AND sourceTime < "${dt}" `,
    where: query,
    sort: 'sourceTime DESC'
  }
  const dataRateList = [0.1, 0.2, 0.39, 0.78, 1.56, 3.13, 6.25, 12.5, 25, 50, 100, 200, 400, 800, 1600, 3200]
  //
  // Shock Event Object has 4 entries, X, Y, Z and RMS (composite value).
  // Each is a 32 element array with an array for each of these 32 elements.
  // The inner array has two entries, index and value.  So the example would
  // be something like.....
  // { X:[ [0,#], [1,#],...], Y:[ [0,#], [1,#],...], RMS: ..}
  //
  return new Promise((resolve, reject) => {
    getMessagesProvider(state, requestPayload831).then(response => {
      // RMS calculation
      const rmsCalc = (x, y, z) => parseFloat(Math.sqrt((x * x) + (y * y) + (z * z)).toFixed(3))
      // shockData.timestamp = response['Device Time (UTC)']
      shockData.timestamp = response.data[0]['Device Time (UTC)']
      // Calculate data rate (hz) and interval of each increment
      // Data rate comes from dataRateList using 'SampRate' value from the raw data
      // interval is inverse * 1000 to convert to ms
      const sampRate = Number(response.data[0].SampRate) % 16
      const dataRate = dataRateList[sampRate]
      shockData.dataRate = dataRate
      shockData.interval = parseFloat((1000 / dataRate).toFixed(2))
      // Process each row of data to enable build X,Y,Z or Xf,Yf,Zf
      const mapFn = function (itm) {
        return  [Number(itm[0].substring(3)), parseFloat(itm[1])]
      }
      for (const val of Object.values(response.data)) {
        const readings = Object.entries(val)
          .filter(itm => itm[0].match('REC'))
          .map(mapFn)
        shockData[val.Axis] = readings
        // skipcq:JS-W1043 - Skip redundant literal in a logical expression
        shockData[`max${val.Axis}`] = Number(val.MaxAcc || '0.000').toFixed(2)
      }
      // .reduce((a, b) => a + b, 0)
      // rms.map((itm, idx) => {})
      // rms.map(() => {})
      // rms.map(() => { return {} })
      // console.log('data out: ', shockData)
      const calcDeltaV = (vec) => {
        const totalAcc = vec.reduce((acc, cur) => acc + cur[1], 0)
        return (9.8 * totalAcc / dataRate).toFixed(4)
      }
      shockData.dX = calcDeltaV(shockData.X)
      shockData.dY = calcDeltaV(shockData.Y)
      shockData.dZ = calcDeltaV(shockData.Z)
      const rms = new Array(shockData.X.length).fill(0).map(() => { return {} })
      if (shockData.X) {
        shockData.RMS = rms.map((val, idx) => [idx, rmsCalc(shockData.X[idx][1], shockData.Y[idx][1], shockData.Z[idx][1])])
      }
      if (shockData.Xf) {
        shockData.RMSf = rms.map((val, idx) => [idx, rmsCalc(shockData.Xf[idx][1], shockData.Yf[idx][1], shockData.Zf[idx][1])])
      }
      commit('updateShockEvent', shockData)
    }).catch(e => {
      reject(e)
    })
  })
}

export const setShockData = ({ commit }, shockData) => {
  commit('updateShockEvent', shockData)
}

/**
 * Load Command File<br>
 * Runs {@link loadCommandFile} to load a set of commands from server based file to a
 * single device or list of devices.
 * @param {object} state - Vuex state
 * @param {string} file - Name of command file
 * @param {string | Array} devices - String with a MAC Id of single device or List of
 * MAC Ids as strings.
 * @returns {Promise} Promise object represents the return value from the function
 */
export const loadCommandFile = ({state}, [file, devices]) => {
  return new Promise((resolve, reject) => {
    loadCommandFileProvider(state, {file, devices}).then(response => {
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * Runs {loadPageDetails} to load the page configuration for the given page name
 * @param {object} state - Vuex state
 * @returns {Promise} Promise object represents the return value from the function
 */
export const loadPageDetails = ({commit, state}, pageName) => {
  return new Promise((resolve, reject) => {
    // If the page options already added in state, return early
    if (state.configuration.pageOptions.pageName) {
      resolve({})
    }
    const promiseList = []
    // promiseList.push(getStaticDataProvider(state, { path: `pageOptions/default/${pageName}` }))
    // Get the default page configurations from the default pageOptions object from configuration
    promiseList.push(getStaticDataProvider(state, {path: 'pageOptions/default', params: {searchPattern: pageName}}))
    // If the pageOptionPatternPages array is not empty and it have the current page options for the company pattern, then add the request to get the page configurations
    if (state.configuration.pageOptionPatternPages.length > 0 && state.configuration.pageOptionPatternPages.indexOf(pageName) > -1) {
      // promiseList.push(getStaticDataProvider(state, { path: `pageOptions/${state.company.pattern}/${pageName}` }))
      promiseList.push(getStaticDataProvider(state, {path: `pageOptions/${state.company.pattern}`, params: {searchPattern: pageName}}))
    }
    // const pageOptionsPayload = { path: 'pageOptions' }
    Promise.all(promiseList).then((responses) => {
      let pageOptions = responses[0][pageName] // The fefault page options for the given page name
      // Check the page configuration response for the pattern is available. If so merge the pattern object with the default one
      if (responses.length > 1) {
        pageOptions = Object.assign({}, pageOptions, responses[1][pageName])
      }
      // Get the overrides for the the cuurent page configuration
      const overrides = Object.keys(state.company.pageOverrides).filter(key => key.includes(pageName))
      // Combine the page options with overrides
      overrides.forEach((key) => {
        let keyList = key.split('.')
        keyList = keyList.slice(1, keyList.length) // Remove the page name from the override key
        const param = keyList.pop()
        const path = keyList.reduce((o, p) => o ? o[p] : null, pageOptions)
        Vue.set(path, param, state.company.pageOverrides[key])
      })
      commit('setPageOptions', {pageName, pageOptions})
      resolve(responses)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * Checks users authentication credentials using {@link loginProvider} and if
 * authorized collects data and sets user state.<br>
 * A critical attribute is userClass.  This variable will set the users menu,
 *  landing page alertGroups and initialization objects.  This value is set in
 * a cascading fashion, with value in user prefs the highest priority, followed
 * by company default, followed by system default.
 * The following actions are performed once authorization is verified:
 * - Authentication state is set using {@link setAuth}
 * - User state is set using {@link setUser}
 * - {@link getCompaniesProvider} is called to get the company id and other
 * data.  Once this is successful, the following is performed:
 *   - Company state is set using {@link setCompanies}
 *   - Initial offset dates are created and set in {@link setInitialOffsets}
 *   - Static data is collected from API (which was set using config.json)
 * using {@link getStaticDataProvider} for:
 *     - The user's company configuration
 *     - General configuration
 *   - WAR file version info is collected {@link getWarVersionProvider} and
 * added to state using {@link setWarVersion}
 *   - User data is collected using {@link getUsersProvider}.  This data is
 * added to state using {@link setUser}
 *   - The following are dispatched to set the data state for key objects
 * in the system:
 *     - {@link initializeTags}
 *     - {@link initializeAlerts}
 *     - {@link initializeGateways}
 *     - {@link initializeShipments}
 *     - {@link initializeLocations}
 *     - {@link initializeGeofences}
 * - At the end, the user is redirected to their home page.<br>
 * IF authenticaion is not successful, various elements are reset in state
 * to their initial values.  These include devices, shipments, gateways, etc.
 * @param {object} state - Vuex state
 * @param {Array} creds - Users credentials
 * @todo make WAR version collection more general
 * @todo Have various objets that are dispatched (tags, shipments, etc) vary
 * based on company configuration.  All don't need to be called every time.
 * Remove general config call in login and use static data instead - consolidate
 * the two methodologies.
 */
export const login = ({ commit, dispatch, state }, creds) => {
  // login using API....
  // if successful:
  //    update auth in state
  //    update user in state
  //    call getCompanies to populate company objects
  //    if successful:
  //      initialize tags, shipments, alerts etc.
  //      route to home (/)
  // otherwise redirect to login
  commit('setAuthError', '')
  loginProvider(state, creds).then(response => {
    if (response.authorized) {
      // update auth and user in state
      commit('setAuth')
      commit('setUser', response)
      // On successfull login, intialize date formats for the user
      dispatch('createConfigObj', 'dateFormats')
      localStorage.setItem('session time', Math.round(Date.now() / 1000)) // Session time update
      const pagePatternsPayload = { path: 'pageOptions', params: {brief: true} }
      const patternsPayload = { path: 'patterns', params: {brief: true} }
      const companyName = response.companyName
      const landingPage = state.user.landingPage
      // get company information and update state
      Promise.all([
        getCompaniesProvider(state),
        getStaticDataProvider(state, pagePatternsPayload),
        getStaticDataProvider(state, patternsPayload)
      ]).then(responses => {
        // get landing page for redirect
        // const landingPage = responses[1].landingPage
        const pageOptionPatterns = responses[1].replace(/[[\]]| /g, '').split(',')
        const patterns = responses[2].replace(/[[\]]| /g, '').split(',')
        // add the landing page value in the state
        // commit('updateUser', {'landingPage': landingPage})
        // commit('setConfig', {'pageOptionPatterns': pageOptionPatterns})
        commit('setConfig', {patterns})
        // Get the filterShipments value from the userClass response
        // let filterShipments = responses[1].filterShipments
        const cpnyProvResp = Object.values(responses[0].data.companyList)[0]
        // build companyUpdates object to update fields from iConnect -> company -> customData
        // this will be applied after changes from dist json.config
        const companyUpdates = {
          // alertGroups: responses[1].alertGroups,
          id: cpnyProvResp.companyId,
          name: companyName,
          groups: Object.values(responses[0].data.companyGroups)[0]
        }
        // Extract any known prefs from customPrefs and add to companyUpdates
        try {
          // Check for custPrefs to allow support for older API variant.
          // New API version (4.3.5+) will use 'else' path
          if (cpnyProvResp.propertyMap.custPrefs) {
            const props = JSON.parse(cpnyProvResp.propertyMap.custPrefs)
            Object.assign(companyUpdates, props)
          } else {
            const props = JSON.parse(cpnyProvResp.propertyMap.customPrefs)
            Object.assign(companyUpdates, cpnyProvResp.propertyMap, props)
            // delete companyUpdates.customPrefs
          }
        } catch (err) {
          console.error('ERROR', err)
        }
        // console.log('COMPANY OBJ: ', companyUpdates)
        // send all to mutator
        // commit('updateUser', {'reports': responses[1].reports})
        // Set offset times based of config.lastUpdateOffsets
        commit('setInitialOffsets')
        //
        const companyPayload = { path: `companies/${cpnyProvResp.companyId}` }
        const menuPayload = { path: `menus/${state.user.menu}` }
        const gatewayPayload = {
          query: 'chargerAndGatewayNames',
          params: [String(cpnyProvResp.companyId)]
        }
        // Update final state elements:
        // company - Use any specific details from jsongConfig, general details
        //           config.json (company) and iConnect (company.customData)
        // warVersion - TBD
        // users - to get user list
        // menu - information from jsonConfig.txt
        // other objects - anything from init list
        //
        // With these details, update state and redirect to landing page
        const promiseList = [
          getStaticDataProvider(state, companyPayload),
          getWarVersionProvider(state),
          getUsersProvider(state),
          getStaticDataProvider(state, menuPayload),
          getDeviceIdsProvider(state, 'DEVICE_IGATE'),
          getQueryProvider(state, gatewayPayload)
        ]
        let pageOptionPatternFound = false
        if (companyUpdates.pattern && pageOptionPatterns.indexOf(companyUpdates.pattern) > -1) {
          pageOptionPatternFound = true
          promiseList.push(getStaticDataProvider(state, { path: `pageOptions/${companyUpdates.pattern}`, params: {brief: true} }))
        }
        let patternFound = false
        if (companyUpdates.pattern && patterns.indexOf(companyUpdates.pattern) > -1) {
          patternFound = true
          promiseList.push(getStaticDataProvider(state, { path: `patterns/${companyUpdates.pattern}` }))
        }
        Promise.all(promiseList).then((responseList) => {
          if (responseList.length > 6 && responseList[6] && pageOptionPatternFound && responseList[6].length !== 0) {
            commit('setConfig', {'pageOptionPatternPages': responseList[6].replace(/[[\]]| /g, '').split(',')})
          }
          if (patternFound) {
            commit('setOverrides', responseList[responseList.length - 1])
          }
          // add the module in the initialized modules array in state to reset on logging out
          if (!state.initialized.includes('company')) {
            commit('setInitializedModule', 'company', { root: true })
          }
          // Commit changes from config.json file in /static
          commit('company/UPDATE_CONFIG', state.configuration.defaultCompany)
          commit('removeConfiguration', 'defaultCompany')
          // Commit changes from json.config company object
          commit('company/UPDATE_CONFIG', responseList[0])
          // Commit changes from iConnect company.customData
          commit('company/UPDATE_CONFIG', companyUpdates)
          //
          commit('setWarVersion', responseList[1])
          commit('setUsers', responseList[2].data.userInfo)
          commit('setMenuConfig', responseList[3])
          commit('setUsersGateways', responseList[4].data)
          commit('setChargerGateways', responseList[5].data)

          // Set the user prefs to localstorage
          const dateFormat = state.configuration.dateFormats[state.user.dateFormat] ? state.configuration.dateFormats[state.user.dateFormat].display : 'D-MMM-YY | M/D'
          const timezone = state.user.timezone
          localStorage.setItem('dateFormat', dateFormat)
          localStorage.setItem('timezone', timezone)


          /* If any overrides of the configuation for the company, add them */
          if (responseList[0].overrides) commit('setOverrides', responseList[0].overrides)
          // If user configuration is not defined, then use the company configuration to filter the shipments
          // if (typeof filterShipments === 'undefined') {
          //   filterShipments = state.configuration.siteOptions.filterShipments
          // }
          // Save the filterShipments value in the user object
          // commit('updateUser', {'filterShipments': filterShipments})
          // Find last repquest page
          // const regex = new RegExp(state.lastRequestedPage.split('?')[0] + '\\b', 'g')
          const regex = new RegExp(`${state.lastRequestedPage.split('?')[0]}\\b`, 'g')
          const menuItems = []
          responseList[3].items.forEach((menu) => {
            (!menu.children) ? menuItems.push(menu.href) : menuItems.push(...menu.children.map(x => x.href))
          })
          menuItems.push('/detail')
          const findMenus = menuItems.filter(x => x.match(regex)) //  Find last requested page in Menu Navigation
          if (state.lastRequestedPage && state.lastRequestedPage.length > 2 && findMenus.length > 0) {
            router.push(state.lastRequestedPage)
            commit('clearLastRequestedPage')
          } else {
            router.push(landingPage)
          }
        })
      })
      // router.push(state.landingPage[response.companyName])
    } else {
      // Call logout in mutations to clear critical state values
      // commit('clearState')
      // console.log('logout')
      // router.push('/login')
      dispatch('logout')
    }
  }).catch(e => {
    // Error in initial login request
    const errorConfig = state.configuration.errorCodes
    if (e.code === 401) { e.message = errorConfig['401'].message }
    if (e.code === 503) { e.message = errorConfig['503'].message }
    commit('setAuthError', e)
  })
}

export const logout = ({ commit, state }) => {
  // localStorage.clear()
  // const initialized = ['tags', 'warehouses']
  state.initialized.forEach(module => {
    commit(`${module}/RESET_STATE`)
  })
  const baseConfig = JSON.parse(BASE_CONFIG)
  commit('company/UPDATE_CONFIG', baseConfig.defaultCompany)
  // commit('warehouses/resetState')
  commit('clearState')
  commit('setConfig', baseConfig) // Add Defaultcompany in configuration state
  // Reset session and server session timers
  localStorage.setItem('session time', 0)
  localStorage.setItem('server session time', 0)
  // Set the loggeout value in local storage to reset the state value in the login page
  // This value used to reset the state value to initial state value in the login.vue page
  localStorage.setItem('loggedOut', true)
  // If current page is not the login page, then redirect
  if (router.history.current.path !== '/login') router.push('/login')
}

export const overrideHostConfig = ({state, commit}, overrides) => {
  // Get the configuration object from state
  const configuration = {...state.configuration}
  const configOverrides = {}
  // Loop through the each item from the override object
  // using it's key and overwrite/merge the value in state
  Object.keys(overrides).forEach((key) => {
    // If override is an object, then merge with actual configuration
    // Else replace the value eith overrides
    configOverrides[key] = ( typeof overrides[key] === 'object' && !Array.isArray(overrides[key]) ) ? Object.assign({...configuration[key]}, overrides[key]) : overrides[key]
  })
  // Tell the state, that override applied to the config
  configOverrides.hostConfiginitialized = true
  // Update the config overrides in state
  commit('setConfig', configOverrides)
}

export const resetPassword = ({state}, payload) => {
  /* requestPassword ....
  */
  return new Promise((resolve, reject) => {
    resetPasswordProvider(state, payload).then(response => {
      // console.log('requestNewPassword Response: ', response)
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}

export const resetServerSession = ({dispatch, state}) => {
  /* resetSession makes an API call to reset session with Tomcat engine
     and refresh key data from the server
     TODO - Refresh key data -i.e. all shipping tables....
  */
  getUsersProvider(state).then(() => {
    // console.log('Users Provider Payload: ', response)
    // console.log('Company Id: ', state.company.id)
    localStorage.setItem('server session time', Math.round(Date.now() / 1000)) // Session time update
  }).catch(() => {
    // Logout when the getUsersProvider response is 401(Unauthorized)
    dispatch('logout')
  })
}

export const resetSession = () => {
  /* resetSession updates session time in local storage
  */
  localStorage.setItem('session time', Math.round(Date.now() / 1000)) // Session time update
}

export const sendUsername = ({state}, email) => {
  /* resetSession makes an API call to reset session with Tomcat engine
     and (eventually) refresh key data from the server
     TODO - Refresh key data -i.e. all shipping tables....
  */
  return new Promise((resolve, reject) => {
    getUsernameProvider(state, email).then(response => {
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * runActivity
 * Runs {@link createAndRunActivityTemplateProvider} to exeute an activity template by
 * creating and running the template.
 * @param {object} state - Vuex state
 * @param {object} args - Object defines activity to be run
 * @param {object} args.template - 'ManualDeleteShipment.txt',
 * @param {object} args.mac - macId to be used for activity creation
 * @param {string} args.guid - The guid to be used for activity creation
 * @param {boolean} args.runByGUID - TBD
 * @returns {Promise} Promise object represents the return value from the create activity function
 */
export const runActivity = ({state}, args) => {
  return new Promise((resolve, reject) => {
    createAndRunActivityTemplateProvider(state, args).then(response => {
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * sendCommands
 * Runs {@link sendCommandsProvider} to send a list of commands to a
 * list of devices.
 * @param {string[]} args.deviceIdList - List of MAC ids.
 * @param {string[]} args.commandList - List of commands.
 * @returns {Promise} Promise object represents the return value from the send commands function
 */
export const sendCommands = ({state}, args) => {
  return new Promise((resolve, reject) => {
    sendCommandsProvider(state, args.deviceIdList, args.commandList).then(response => {
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}

export const updateUser = ({commit, state}, updatePayload) => {
  // current custom prefs:
  //   - timezone - users select timezone
  //   - userClass - users current class
  //   - temperatureUnit - users temperature unit preference in celsius format
  const customPrefs = state.user.propertyMap.customPrefs ? JSON.parse(state.user.propertyMap.customPrefs) : {}
  customPrefs.temperatureUnit = updatePayload.temperatureUnit
  customPrefs.timezone = updatePayload.timezone
  customPrefs.dateFormat = updatePayload.dateFormat
  if (state.user.userClass) customPrefs.userClass = state.user.userClass
  const data = {
    username: updatePayload.loginName,
    loginName: updatePayload.loginName,
    fullName: updatePayload.fullName,
    emailAddress: updatePayload.emailAddress,
    notifyList: updatePayload.notifyList,
    phoneNumber: updatePayload.phoneNumber,
    smsCarrier: updatePayload.smsCarrier,
    propertyMap: {
      customPrefs: JSON.stringify(customPrefs)
    }
    // Need to add:
    // Others to consider: password, groupList, enabled
  }
  return new Promise((resolve, reject) => {
    // NEED TO CHANGE TO UPDATE USER PROVIDER
    updateUserProvider(state, data).then(() => {
      commit('updateUser', data)
      const msg = `Successfully updated user: ${updatePayload.userId}`
      resolve(msg)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * Calls login function with password concatenated w/ confirmation
 * codean Route Map from and returns api response.
 * @param {object} creds - Payload with username, password and confirmation code
 * @return {Promise} Promise object
 */
export const verifyPassword = ({ state }, creds) => {
  return new Promise((resolve, reject) => {
    loginProvider(state, creds).then(response => {
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * Update the filters to Vuex store
 */
export const updateFilterState = ({ commit }, filterState = []) => {
  const filterPayload = {
    type: filterState[0],
    filters: filterState[1],
    reset: typeof filterState[2] === 'undefined' ? false : filterState[2]
  }
  commit('UPDATE_FILTERS', filterPayload)
}
/**
 * Send Email<br>
 * Runs {@link sendEmailProvider} to load a send an email via the iConnect server.
 * @param {Object} email - list of recipients as a comma delimited string of email addresses
 * @returns {Promise} Promise object represents the return value from the function
 */
export const sendEmail = ({state}, email) => {
  return new Promise((resolve, reject) => {
    sendEmailProvider(state, email).then(response => {
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * ssoLogin - Implements the single sign on request using the
 * onelogin provider.
 * @param {String} client - Client ID to get the client configurations
 * Finally redirects to the end point
 */
/* export const ssoLogin = ({state}, clientConfig) => {
  // Get the client configurations
  // const {path, params} = JSON.parse(folwObj)
  // Get the callback path and request params
  const redirectUri = getUrl('/login')
  clientConfig.redirect_uri = redirectUri
  const path = clientConfig.accessCodeRequestPath
  // Redirect path construction
  // let requestPath = `${path}?client_id=${clientId}&response_type=id_token&nonce=test&scope=openid profile params&redirect_uri=${callbackPath}`
  let requestPath = `${path}?`
  // Loop through the param item configured and assign the value to the params
  clientConfig.accessCodeRequestParams.forEach(param => {
    let paramValue = ''
    if (Object.prototype.hasOwnProperty.call(clientConfig, param)) paramValue = clientConfig[param]
    requestPath = `${requestPath}${param}=${paramValue}&`
  })
  // Remove the trailing end & in the request path
  if (requestPath.slice(-1) === '&') requestPath.substring(0, requestPath.length - 1)
  // Redirect to the sso provider application
  window.location.assign(requestPath)
} */

/**
 * Import configuration values from jsonconfig file
 */
export const createConfigObj = ({ commit, state }, objType) => {
  const configKey = {path: objType}
  getStaticDataProvider(state, configKey).then(response => {
    Object.entries(response).forEach(alertGroup => {
      const payload = {
        objType,
        key: alertGroup[0],
        value: alertGroup[1]
      }
      commit('SET_CONFIG_OBJECT', payload, objType)
    })
  })
}

/**
 * Generate Report<br>
 *
 */
export const generateJsReport = ({state}, payload) => {
  return new Promise((resolve, reject) => {
    jsReportProvider(state, payload).then(response => {
      const toBlob = response.toBlob()
      // If preview is true, then do not download and open the pdf in new tab
      if (payload.moreData.preview) {
        const url = window.URL.createObjectURL(toBlob)
        window.open(url)
      } else { // Else download the file
        const toDataURI = response.toDataURI()
        const fileName = payload.moreData.fileName
        if (window.navigator.msSaveBlob) { // Download script for IE
          window.navigator.msSaveBlob(toBlob, fileName)
        } else {
          const hiddenElement = document.createElement('a')
          hiddenElement.type = toBlob.type
          hiddenElement.href = toDataURI
          hiddenElement.target = '_blank'
          hiddenElement.download = fileName
          document.getElementById('downloadCsvSection').appendChild(hiddenElement) // For Firefox, need to append a <a> element to downloadCsvSection div
          hiddenElement.click()
        }
      }
      resolve(response)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * @param {String} pageName - Page name to get the message from configuration
 */
export const getPageMessage = ({commit, state}, pageName) => {
  // Get the user's preferred language from browser
  const userLang = navigator.language || navigator.userLanguage
  // If the user language configurred in the message object use it
  // Else use en as the default
  const messagesObj = messages[userLang] || messages.en
  // If the message object configured for the page name,
  // then store in the state obj
  if (Object.prototype.hasOwnProperty.call(messagesObj, pageName)) {
    let msg = messagesObj[pageName]
    // Override the msg object from company configuration
    const overrides = state.company.msgOverrides
    if (overrides[pageName]) msg = Object.assign(msg, overrides[pageName])
    commit('setMessages', {msg, pageName})
  }
}
