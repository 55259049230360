<template>
  <intelyt-modal
    :isActive="isActive"
    :title="modalTitle"
    :copyLinkTxt="copyLinkTxt"
    :uniqueId="uniqueId"
    v-on:close="close()"
  >
    <section class="modal-card-body">
      <div class="content">
        <div class="tabs is-boxed">
            <ul class="noMarginleft">
              <li v-for="(tab, idx) in chartTabs" :key="idx" :class="{'is-active': (activeTab === idx)}"><a @click="activeTab = idx">{{tab.name}} </a></li>
            </ul>
          </div>
          <div class="content">
            <div v-for="(tab, idx) in chartTabs" :key="idx" :id="idx" class="content-tab" v-show="activeTab === idx">
              <template v-if="activeTab === idx">
                <base-chart :classname="chartStyle"
                  :options='tab.chartOptions'
                  :showLoadingIcon='showLoadingIcon'
                ></base-chart>
              </template>
            </div>
          </div>
      </div>
    </section>
    <template slot="footer">
      <a class="button" @click="close()">Close</a>
    </template>
  </intelyt-modal>
</template>

<script>
import IntelytModal from '@/components/IntelytModal'
import {calcDeviceCharge, round, getUrl, getDateFormat} from '@/store/helpers.js'
import {chartOptionFactory, chimeNetworkChartFactory} from '@/store/factories'
import {shortDate} from '@/store/formatters.js'
import { mapActions, mapState } from 'vuex'
import moment from 'moment-timezone'

export default {
  components: {
    IntelytModal
  },
  computed: {
    chartTabs () {
      const shipmentdata = this.$store.state.shipments.all[this.guid]
      // If the shipment data is not found or chartList is false or empty chartList means return empty {}
      if (!shipmentdata || !this.chartList || !this.chartList.length) return {}
      let tabs = {}
      if (this.chartList.includes('communication')) tabs.communication = {name: 'Communication', chartOptions: this.getChartOptions('communication')}
      if (this.chartList.includes('acl')) tabs.chimeNetwork = {name: 'Network', chartOptions: this.getChartOptions('battery')}
      if (shipmentdata.chimeShockTilt && Object.keys(shipmentdata.chimeShockTilt).length > 0) {
        if (this.chartList.includes('shock')) tabs.chimeShock = {name: 'Shock', chartOptions: this.getChartOptions('shock')}
        if (this.chartList.includes('tilt')) tabs.chimeTilt = {name: 'Tilt', chartOptions: this.getChartOptions('tilt')}
      }
      if (shipmentdata.chimeTempHumid && Object.keys(shipmentdata.chimeTempHumid).length > 0) {
        if (this.chartList.includes('humid')) tabs.humid = {name: 'Humidity', chartOptions: this.getChartOptions('humidity')}
        if (this.chartList.includes('humidLine')) tabs.humidLine = {name: 'Humidity', chartOptions: this.getLineChartOptions('humidity')}
        if (this.chartList.includes('temp')) tabs.temp = {name: 'Temperature', chartOptions: this.getChartOptions('temperature')}
        if (this.chartList.includes('tempLine')) tabs.tempLine = {name: 'Temperature', chartOptions: this.getLineChartOptions('temperature')}
      }
      return tabs
    },
    copyLinkTxt: function () {
      return getUrl(`${this.$route.fullPath}&m=chimeNetwork`)
    },
    uniqueId: function () {
      if (!this.$store.state.shipments.all[this.guid]) return ''
      const shipmentdata = this.$store.state.shipments.all[this.guid]
      return shipmentdata.macId
    },
    /* showShockTiltChart: function () {
      if (!this.$store.state.shipments.all[this.guid]) return false
      const shipmentdata = this.$store.state.shipments.all[this.guid].chimeData
      // console.log('shipment data: ', shipmentdata)
      return (typeof shipmentdata !== 'undefined' && Object.entries(shipmentdata).length > 0)
    }, */
    chartStyle: function () {
      // Use number of chimes to select the appropriate class to size the chart
      if (this.chimeCount < 6) {
        return 'modal-chart-sm'
      } else if (this.chimeCount < 12) {
        return 'modal-chart-md'
      } else if (this.chimeCount < 21) {
        return 'modal-chart-lg'
      } else if (this.chimeCount < 38) {
        return 'modal-chart-xl'
      } else {
        return 'modal-chart-xxl'
      }
    },
    gridHeight: function () {
      // Use number of chimes to select the appropriate class to size the chart
      if (this.chimeCount < 6) {
        return '60%'
      } else if (this.chimeCount < 12) {
        return '65%'
      } else if (this.chimeCount < 21) {
        return '70%'
      } else if (this.chimeCount < 38) {
        return '75%'
      } else {
        return '80%'
      }
    },
    ...mapState(['shockEvent'])
  },
  data () {
    return {
      modalTitle: 'Chime Network Detail',
      activeTab: '',
      showLoadingIcon: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    getChartData (type, arrDates) {
      // Sort function to enable sort by MacId
      const sortFn = (a, b) => {
        if (a[0] > b[0]) return 1
        return -1
      }
      const sortFn2 = (a, b) => {
        if (a > b) return 1
        return -1
      }
      const sortDates = (a, b) => {
        const valid = (moment(a).valueOf() > moment(b).valueOf())
        return valid ? 1 : -1
      }
      // Build default object to return if data isn't yet available
      let chimeDataObj = {macIds: [], times: [], chmData: [], all: []}
      const shipdata = {...this.$store.state.shipments.all[this.guid]}
      // Get all the chimes in the shipment
      const allChimeList = Object.values(shipdata.chimes).map(dev => dev.shortMac).sort(sortFn2)
      let allData = {}
      switch (type) {
        case 'battery':
        case 'communication':
          allData = {...shipdata.chimeData}
          break
        case 'shock':
        case 'tilt':
          allData = {...shipdata.chimeShockTilt}
          break
        case 'temperature':
        case 'humidity':
          allData = {...shipdata.chimeTempHumid}
          break
        case 'default':
          allData = {...shipdata.chimeData}
          break
      }
      const getStaticData = function () {
        const tempData = {}
        // Loop through the chimes to get the records
        allChimeList.forEach((key) => {
          let dataString = ''
          switch (type) {
            case 'battery':
            case 'communication':
              dataString = 'B:0, C:0'
              break
            case 'shock':
            case 'tilt':
              dataString = 'S:0, T:0'
              break
            case 'temperature':
            case 'humidity':
              dataString = 'H:0, T:0'
              break
            case 'default':
              dataString = 'B:0, C:0'
              break
          }
          tempData[key] = dataString
        })
        return tempData
      }
      if (typeof allData !== 'undefined' && Object.entries(allData).length > 0) {
        chimeDataObj.allData = allData
        let times = Object.keys(allData).reverse().map(x => moment.utc(Number(x)).tz(this.$store.state.user.timezone).format('MM-DD-YY HH:mm'))
        // get the dates if isn't yet avaialble in 'times' Array
        let getMissingDate = arrDates.filter((date) => { return !allData[date.valueOf()] })
        // If atlease one missing date found, add it in times array and add blank values fot that time
        if (getMissingDate.length !== 0) {
          const missingDateStr = getMissingDate.map(d => shortDate(d, 'MM-DD-YY HH:mm'))
          times = times.concat(missingDateStr)
          // times = times.sort()
          getMissingDate.forEach((date) => {
            const timestamp = date.valueOf()
            // Set blank data for the marklines for each chime records
            allData[timestamp] = getStaticData()
          })
          // console.log('chmData', allData)
        }
        // Sort the data order using the timestamp value
        const sortedData = Object.keys(allData).sort().map(key => allData[key])
        allData = sortedData
        times.sort(sortDates)
        times = times.map(date => moment(date).format(`${getDateFormat('short')} HH:mm`))
        // times = getMissingDate.length !== 0 ? times.sort() : times
        let chmData = []
        Object.values(allData).forEach((ele, idx) => {
          let entries = Object.entries(ele).sort(sortFn).map((ele2, idx2) => {
            // console.log('ele2', ele2)
            let val = ''
            switch (type) {
              case 'battery':
                val = round(calcDeviceCharge(this.$store.state, {voltage: Math.min(ele2[1].split(',')[0].split(':')[1], ele2[1].split(',')[1].split(':')[1])}) * 100, 0)
                break
              case 'shock':
              case 'humidity':
                val = parseInt(ele2[1].split(',')[0].split(':')[1])
                break
              case 'communication':
              case 'tilt':
              case 'temperature':
                val = parseInt(ele2[1].split(',')[1].split(':')[1])
                break
              case 'default':
                val = ele2
                break
            }
            return [idx2, idx, val]
          })
          chmData.push(...entries)
        })
        // const macIds = Object.keys(Object.entries(allData)[0][1]).sort(sortFn2)
        chimeDataObj.chmData = chmData
        chimeDataObj.macIds = allChimeList
        chimeDataObj.times = times
        // console.log('30/1 chm date', chmData, times, chimeDataObj)
      }
      return chimeDataObj
    },
    getChartDatesAndMarkLines () {
      // Get the alert list from the page configuration
      const alertList = this.alertList
      const shrtDateFmt = `${getDateFormat('short')} HH:mm`
      // Filter the alerts available for the shipment and alerts configured for the page
      const filteredAlerts = Object.values(this.$store.state.alerts.all).filter((alert) => { return (alert.guid === this.guid && alertList.includes(alert.event)) })
      let chartDates = []
      const chimeMarkLines = []
      // Get the alert Event configurations
      const allAlerts = this.$store.state.configuration.alertEvent
      // Loop the alerts and prepare the date and mark line list
      filteredAlerts.forEach((alert) => {
        const alertObj = {...allAlerts[alert.event]}
        // Date value for the tooltip
        // Date value for the axis
        const shrtAlrtTime = shortDate(alert.time, shrtDateFmt)
        chimeMarkLines.push({xAxis: shrtAlrtTime, value: alertObj.name, symbol: 'none', label: { normal: { formatter: alertObj.name } }})
        chartDates.push(alert.time)
      })
      chartDates = Array.from(new Set(chartDates))
      return {
        chartDates,
        chimeMarkLines
      }
    },
    /**
     * getChartOptions - Returns the chart options for the given chart type. The chart type options are battery,
     * shock, tilt, temperature and humidity
     * This method takes the type argument and retrieve the chart options and vmap options from the conffigurations
     * and apply it to the chart object. Also preapres the title, tooltip, x-axis, y-axis and it's time series data
     * from the shipment object.
     */
    getChartOptions (type) {
      let chartConfig = {...this.$store.state.configuration.charts.chimeNetworkChartOptions[type]} || {}
      // Get the marker lines and chart dates for the chart fromt he shipment data
      const getDatesAndLines = this.getChartDatesAndMarkLines()
      const chartDates = getDatesAndLines.chartDates
      const chimeMarkLines = getDatesAndLines.chimeMarkLines
      // Get the series data for the given chart type from the shipment data
      const getChartData = this.getChartData(type, chartDates)
      const timestamps = getChartData.times
      const chimes = getChartData.macIds
      let seriesData = getChartData.chmData
      // Remove the battery records, which is < 1
      if (type === 'battery') seriesData = seriesData.filter(b => b[2] > 0)
      // Remove the humidity records, which is < 1 & > 100
      if (type === 'humidity') seriesData = seriesData.filter(h => h[2] > 0 && h[2] < 100)
      // Set the grid height for the chart
      chartConfig.gridHeight = this.gridHeight
      // If vmap not found in config, then set empty object
      if (!chartConfig.vMap) chartConfig.vMap = {}
      // If the chart type is temperature then apply the unit value from the user settings
      // And apply the min and max value from the temperature scale configuration
      // if (type === 'temperature' && chartConfig.hasOwnProperty('labelUnit')) {
      if (type === 'temperature' && Object.prototype.hasOwnProperty.call(chartConfig, 'labelUnit')) {
        // Defines the the unit is C/F--> C - Celsius
        const isC = this.$store.state.user.temperatureUnit === 'Celsius'
        // Filter to remove any bad data (temp of exactly 32F or 0 C is a bad record)
        const tempFilter = function (t) {
          if (isC) {
            return t[2] !== 0.0
          } else {
            return t[2] !== 32.0
          }
        }
        seriesData = seriesData.filter(tempFilter)
        const unit = isC ? ' \xB0C' : ' \xB0F'
        const tempScale = this.$store.state.configuration.charts.scales.temperature
        chartConfig.labelUnit = unit
        chartConfig.vMap.min = isC ? tempScale.minC : tempScale.minF
        chartConfig.vMap.max = isC ? tempScale.maxC : tempScale.maxF
        chartConfig.vMap.text = [unit, unit]
      }
      const opts = chimeNetworkChartFactory(chimes, timestamps, seriesData, chimeMarkLines, chartConfig)
      return opts
    },
    getLineChartOptions (type) {
      // If the shipment is not found then return early
      if (!this.$store.state.shipments.all[this.guid]) return {}
      // Get the shipment data
      const shipdata = {...this.$store.state.shipments.all[this.guid]}
      let allData = {}
      // Get the chime data based on the type
      switch (type) {
        case 'battery':
          allData = {...shipdata.chimeData}
          break
        case 'shock':
        case 'tilt':
          allData = {...shipdata.chimeShockTilt}
          break
        case 'temperature':
        case 'humidity':
          allData = {...shipdata.chimeTempHumid}
          break
        case 'default':
          allData = {...shipdata.chimeData}
          break
      }
      const sortFn = (a, b) => {
        if (a > b) return 1
        return -1
      }
      const devDataObj = {}
      // const seriesData = []
      // Prepare the date list
      const timestamps = Object.keys(allData).sort(sortFn)
      // Prepare the device list
      const deviceList = Object.keys(Object.values(allData)[0]).sort(sortFn)
      const vm = this
      // Define the temperature unit based on user config
      const isC = this.$store.state.user.temperatureUnit === 'Celsius'
      // Loop through the date value and prepare the chart value
      timestamps.forEach(tmstmp => {
        const data = allData[tmstmp]
        deviceList.forEach(dev => {
          if (!devDataObj[dev]) devDataObj[dev] = []
          let val = ''
          let valid = false
          const devData = data[dev] || false
          // If message set isn't complete such that device data is found,
          // skip the processing..
          if (devData) {
            switch (type) {
              case 'battery':
                val = round(calcDeviceCharge(vm.$store.state, {voltage: Math.min(devData.split(',')[0].split(':')[1], devData.split(',')[1].split(':')[1])}) * 100, 0)
                valid = val > 0
                break
              case 'shock':
              case 'humidity':
                val = parseInt(devData.split(',')[0].split(':')[1])
                valid = val > 0 && val < 100
                break
              case 'tilt':
              case 'temperature':
                val = parseInt(devData.split(',')[1].split(':')[1])
                valid = isC ? val !== 0.0 : val !== 32.0
                break
              case 'default':
                val = devData
                break
            }
          }
          // devDataObj[dev].push([Number(tmstmp), val])
          if (valid) devDataObj[dev].push(Number(val))
        })
      })
      // Prepare the series data for each device
      const seriesData = deviceList.map((dev) => { return {name: dev, data: devDataObj[dev]} })
      // const yAxis = deviceList.map((d, idx) => { return {name: d, index: idx}})
      // Get the timezone value from user config
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      // Prepare the x axis for the chart
      const xAxis = {
        type: 'category',
        boundaryGap: false,
        data: timestamps,
        axisLabel: {
          interval: 'auto',
          overflow: 'break',
          width: 60,
          formatter (value) { // XAxis label formatter
            return moment(Number(value)).tz(tmz).format(`${getDateFormat('short')} HH:MM`)
          }
        }
      }
      const suffixObj = {
        battery: '%',
        shock: 'g',
        tilt: '\xB0',
        temperature: isC ? ' \xB0C' : ' \xB0F',
        humidity: '%',
      }
      // Suffix unit for the value
      const suffix = suffixObj[type] || '%'
      // Get chart object
      const options = {legend: {bottom: 5}, yAxis: {type: 'value'}, xAxis}
      const chartConfig = this.$store.state.configuration.charts
      // Get the chart data from chart factory
      const chartData = chartOptionFactory(chartConfig, '', seriesData, options, {}, tmz)
      // Define the tooltip value
      chartData.tooltip = {
        'trigger': 'axis',
        formatter (val) {
          const tooltipItms = val.map(itm => `${itm.marker} <b>${itm.seriesName}</b>: ${itm.value}${suffix}`).join('</br>')
          let out = `${moment(Number(val[0].name)).tz(tmz).format(`${getDateFormat('short')} HH:MM`)}</br>`
          out = `${out} ${tooltipItms}`
          return out
        }
      }
      return chartData
    },
    ...mapActions(['shipments/fetchChimeData'])
  },
  props: {
    isActive: {
      type: Boolean
    },
    guid: {
      type: String,
      required: true
    },
    alertList: {
      type: Array,
      default: () => []
    },
    chartList: {
      type: [Boolean, Array],
      default: false
    },
    chimeCount: {
      type: Number,
      default: 1
    }
  },
  watch: {
    // whenever selected shipment changes, this function will run
    isActive: {
      handler: function (newInput) {
        if (newInput) {
          this.activeTab = this.chartList.includes('communication') ? 'communication' : 'chimeNetwork'
          // Window opened, check to see if the data already exists
          if (!this.$store.state.shipments.all[this.guid].chimeData) {
            // modal has been activated, get data...
            const shipment = this.$store.state.shipments.all[this.guid]
            this['shipments/fetchChimeData']([shipment.macId, this.guid, this.chartList])
          }
        }
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.modal-chart-sm {
  height: 350px;
}
.modal-chart-md {
  height: 450px;
}
.modal-chart-lg {
  height: 600px;
}
.modal-chart-xl {
  height: 800px;
}
.modal-chart-xxl {
  height: 1200px;
}
</style>
